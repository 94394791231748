import { GetGuestConnect } from 'core/API/workplaces';
import Loading from 'core/components/Loading/Loading';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import GuestConnect from './GuestConnect';

export interface IurlParams {
    workplaceIdentifier: string;
    domainIdentifier: string;
}

export default function GuestConnectContainer(): JSX.Element {
    // const [state, setstate] = useState<IGuestConnect>(null);
    const { workplaceIdentifier }: IurlParams = useParams();

    const { isLoading, data: state } = useQuery(
        ['GuestConnect', workplaceIdentifier],
        () => GetGuestConnect(workplaceIdentifier),
        {}
    );

    return !isLoading ? <GuestConnect state={state} /> : <Loading />;
}
