import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ROUTES } from 'module/navigation/enums/routes.enums';
// import { ROUTES } from 'module/navigation/enums/routes.enums';

export function initializeApiInterceptors(httpClient: AxiosInstance): void {
    interceptRequest(httpClient);
    interceptResponse(httpClient);
}

export function getTokenFromLocalStorage(option: string): any {
    const data = window.localStorage.getItem('BYOD-app');
    if (data) {
        const parsed = JSON.parse(data || '');
        if (parsed) {
            return parsed[option];
        }
    }

    return '';
}

function appendToken(config: AxiosRequestConfig, token: string): void {
    config.headers.Authorization = `Bearer ${token}`;
}

function interceptRequest(httpClient: AxiosInstance): void {
    httpClient.interceptors.request.use(
        (config) => {
            // check token and append it
            const token = getTokenFromLocalStorage('token_key');
            token && appendToken(config, token);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

function interceptResponse(httpClient: AxiosInstance): void {
    httpClient.interceptors.response.use(
        (response) => response,
        (error) => {
            if (!error.response) {
                if (error.message === 'Network Error') {
                    console.error('Network error - server is probably down');
                } else {
                    console.error('Unkown error', error);
                }
            } else {
                switch (error.response.status) {
                    case 401:
                        // just quick fix to prevent old stagign bug
                        window.localStorage.removeItem('BYOD-app');
                        window.location.href = ROUTES.LOGIN;
                        break;
                    case 404:
                        // add later on
                        // window.location.href = ROUTES.LOGIN;
                        // on token expire
                        // if (!sessionQuery.refreshToken) {
                        //     window.location.href = ROUTES.LOGIN;
                        // } else {
                        //     return sessionAPIService
                        //         .refreshToken(sessionQuery.refreshToken)
                        //         .then((data) => sessionStore.handleToken(data))
                        //         .then(() => {
                        //             sessionQuery.token &&
                        //                 appendToken(
                        //                     error.config,
                        //                     sessionQuery.token
                        //                 );
                        //             return axios.request(error.config);
                        //         });
                        // }
                        break;
                }
            }

            return error.response;
        }
    );
}
