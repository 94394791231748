import { IUsers } from 'core/interfaces/userInterface';
import { apiService } from 'core/services/apiService';

const GetUsersList = async (workplaceID: number): Promise<IUsers[]> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(
            `users-workplaces/${workplaceID}?active=1&deleted=0`
        )
    );

    const resultArray = Object.keys(data).map(function (personNamedIndex) {
        const person = data[personNamedIndex];
        return person;
    });

    return resultArray;
};

export { GetUsersList };
