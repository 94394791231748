import { FC } from 'react';

import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Icons from 'styles/icons.module.scss';
import Buttons from 'styles/buttons.module.scss';

interface INavButton {
    icon?: string;
    label: string;
    isDisabled?: boolean;
    isOutlined?: boolean;
    isInactive?: boolean;
    onClick?: (event?: any) => void;
}

const NavButton: FC<INavButton> = ({
    icon,
    label,
    isDisabled,
    isOutlined,
    isInactive,
    ...props
}) => {
    return (
        <button
            className={`${
                isOutlined && !isDisabled
                    ? Buttons.btnNavOutlined
                    : isInactive
                    ? Buttons.btnNavInactive
                    : Buttons.btnNav
            }`}
            disabled={isDisabled}
            {...props}
        >
            {icon && <SvgIcon spriteUrl={icon} className={Icons.navIcon} />}
            {label}
        </button>
    );
};

export default NavButton;
