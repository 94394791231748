import { ChangeEvent, ChangeEventHandler, FC, useState } from 'react';

import minus from 'assets/icons/Minus.svg';
import add from 'assets/icons/AddIcon.svg';

import { IFilters } from '../../interfaces/filters.interface';

import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Filter from './Filters.module.scss';

const Filters: FC<IFilters> = ({ filters, setFilters }) => {
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);

    const handleCheck: ChangeEventHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { name, checked } = event.target;

        let tempFilters;

        if (name === 'All') {
            tempFilters = filters.map((filter) => {
                return {
                    ...filter,
                    type: {
                        ...filter.type,
                        active: checked,
                    },
                };
            });
        } else {
            tempFilters = filters.map((filter) =>
                filter.label === name
                    ? {
                          ...filter,
                          type: {
                              ...filter.type,
                              active: checked,
                          },
                      }
                    : filter
            );
        }

        setFilters(tempFilters);
    };

    return (
        <div className={Filter.filtersWrapper}>
            <div className={Filter.filtersHeader}>
                <span className={Filter.filtersTitle}>Filters</span>
                {isFilterOpen ? (
                    <SvgIcon
                        spriteUrl={minus}
                        className={Filter.filtersIcon}
                        onClick={() => setIsFilterOpen(false)}
                    />
                ) : (
                    <SvgIcon
                        spriteUrl={add}
                        className={Filter.filtersIcon}
                        onClick={() => setIsFilterOpen(true)}
                    />
                )}
            </div>
            {isFilterOpen && (
                <div className={Filter.filtersGroup}>
                    <label
                        key={0}
                        htmlFor="All"
                        className={Filter.filtersCheckbox}
                    >
                        <input
                            className={Filter.filtersCheckboxInput}
                            type="checkbox"
                            name="All"
                            id="All"
                            value="All"
                            checked={
                                filters.filter((item) => !item?.type.active)
                                    .length === 0
                            }
                            onChange={(e) => handleCheck(e)}
                        />
                        All
                    </label>
                    {filters.map((filter) => (
                        <label
                            key={filter.id}
                            htmlFor={filter.value}
                            className={Filter.filtersCheckbox}
                        >
                            <input
                                className={Filter.filtersCheckboxInput}
                                type="checkbox"
                                name={filter.label}
                                id={filter.label}
                                value={filter.label}
                                checked={filter?.type.active || false}
                                onChange={(e) => handleCheck(e)}
                            />
                            {filter.label}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Filters;
