import { FC } from 'react';

import closeIcon from 'assets/icons/Close.svg';

import Portal from './Portal';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Modal from './modal.module.scss';
import Icons from 'styles/icons.module.scss';

interface IModal {
    close: () => void;
    render: any;
    title: string;
    icon?: string;
    bigModal?: boolean;
}

const Remodal: FC<IModal> = ({
    children,
    close,
    render,
    title,
    icon,
    bigModal,
}) => {
    return (
        <Portal>
            <div className={Modal.modalRoot}>
                <div
                    className={
                        bigModal ? Modal.modalBigWrapper : Modal.modalWrapper
                    }
                >
                    <div className={Modal.modalHeader}>
                        <div className={Modal.modalTitle}>
                            {icon && (
                                <SvgIcon
                                    spriteUrl={icon}
                                    className={Icons.modalIcon}
                                />
                            )}
                            <h4>{title}</h4>
                        </div>
                        <SvgIcon
                            spriteUrl={closeIcon}
                            className={Modal.modalCloseIcon}
                            onClick={close}
                        />
                    </div>
                    {render(children) || children}
                </div>
                <div className={Modal.modalBackdrop} onClick={close} />
            </div>
        </Portal>
    );
};

export default Remodal;
