import { FC, useState } from 'react';

import EmployeeClockEntries from '../components/EmployeeClockEntries/EmployeeClockEntries';
import EmployeePerformance from '../components/EmployeePerformance/EmployeePerformance';
import SalesGraph from '../components/SalesGraph/SalesGraph';

// CSS modules
import {
    GetEmployeeAttendance,
    GetLaborsaborCalculation,
    GetSalesInfo,
} from 'core/API/laborsaber';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import moment from 'moment';
import { useQuery } from 'react-query';
import DailyLaborCost from '../components/DailyLaborCost/DailyLaborCost';
import MAD from '../components/MAD/MAD';
import NetSales from '../components/NetSales/NetSales';
import { SalesNumber } from '../interface/Sales';
import { getBusinessDay } from '../utils/utils';
import Ls from './Laborsaber.module.scss';

const Laborsaber: FC = () => {
    const {
        workkplaceID,
        data: workplacesData,
        timezone,
    } = useWorkplacesContext();

    const [percentage, setPercentage] = useState(0);

    const { isLoading, data: Sales } = useQuery(
        'SalesInfo',
        async () => {
            const [firstBusinessDay, lastBusinessDay, activeTime] =
                getBusinessDay(timezone);

            const returnData = await GetSalesInfo({
                workkplaceID,
                formatedFirstDay: firstBusinessDay,
                formatedLastDay: lastBusinessDay,
                activeTime,
            });

            const situationRoomResp = await GetLaborsaborCalculation({
                workkplaceID,
                formatedFirstDay: firstBusinessDay,
                formatedLastDay: lastBusinessDay,
            });

            const employeeAttendance = await GetEmployeeAttendance({
                workkplaceID,
                formatedFirstDay: firstBusinessDay,
                formatedLastDay: lastBusinessDay,
            });

            const FormatedData: SalesNumber = {
                daily_labor_cost: {
                    today: {
                        actual_labor_cost: parseFloat(
                            returnData.daily_labor_cost.today.actual_labor_cost
                        ),
                        mabel_labor_savings: parseFloat(
                            returnData.daily_labor_cost.today
                                .mabel_labor_savings
                        ),
                        projected_labor_cost: parseFloat(
                            returnData.daily_labor_cost.today
                                .projected_labor_cost
                        ),
                    },
                    last_week: {
                        actual_labor_cost: parseFloat(
                            returnData.daily_labor_cost.last_week
                                .actual_labor_cost
                        ),
                        mabel_labor_savings: parseFloat(
                            returnData.daily_labor_cost.last_week
                                .mabel_labor_savings
                        ),
                        projected_labor_cost: parseFloat(
                            returnData.daily_labor_cost.last_week
                                .projected_labor_cost
                        ),
                    },
                },
                sales: {
                    today: {
                        hourly_labor_cost: parseFloat(
                            returnData.sales.today.hourly_labor_cost
                        ),
                        net_sales: parseFloat(returnData.sales.today.net_sales),
                    },
                    last_week: {
                        hourly_labor_cost: parseFloat(
                            returnData.sales.last_week.hourly_labor_cost
                        ),
                        net_sales: parseFloat(
                            returnData.sales.last_week.net_sales
                        ),
                    },
                },
                situation_room: situationRoomResp,
                employee_attendance: employeeAttendance,
            };

            return FormatedData;
        },
        {
            refetchInterval: 900000, // 15 min
            onSuccess: (data) => {
                const ProjectedCost =
                    data.daily_labor_cost.today.projected_labor_cost;

                let shiftCalculation = 0;

                data.situation_room.items.forEach((employee) => {
                    data.employee_attendance.items.forEach(
                        (employee_attendance) => {
                            if (
                                employee_attendance.user.id ===
                                employee.labor.user_id
                            ) {
                                const calculateHours = Math.ceil(
                                    moment(
                                        employee_attendance.employee_left
                                    ).diff(
                                        employee.labor.proposed_cut_time,
                                        'minutes'
                                    ) / 60
                                );

                                shiftCalculation +=
                                    calculateHours *
                                    parseInt(employee.labor.wage_hourly_costs);
                            }
                        }
                    );
                });

                const percentage = (shiftCalculation / ProjectedCost) * 100;

                if (!isFinite(percentage)) setPercentage(0);

                setPercentage(percentage || 0);
            },
        }
    );

    return (
        <>
            <section className="Content">
                <div
                    style={{
                        width: '100%',
                        minHeight: 'calc(100vh - 148px)',
                        background: '#ffffff',
                        padding: '24px 24px 40px 24px',
                        borderRadius: '8px',
                    }}
                >
                    <section style={{ maxWidth: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p
                                style={{
                                    marginLeft: '16px',
                                    fontFamily: 'Gotham',
                                    fontStyle: 'normal',
                                    fontSize: '22px',
                                    lineHeight: '32px',
                                    fontWeight: 400,
                                    letterSpacing: '-1px',
                                    color: '#841d80',
                                }}
                            >
                                {workplacesData?.items?.map(
                                    ({ workplace }: any) =>
                                        workplace.id === workkplaceID &&
                                        workplace.name
                                )}
                            </p>
                        </div>
                    </section>
                    <section className={Ls.laborsaberGrid}>
                        <NetSales isLoading={isLoading} sales={Sales} />
                        <DailyLaborCost isLoading={isLoading} sales={Sales} />
                        <MAD percentage={percentage} />
                        <SalesGraph />
                        <EmployeePerformance />
                        <EmployeeClockEntries />
                        {/* <OrderEvents data={orderEventsData} /> */}
                    </section>
                </div>
            </section>
        </>
    );
};

export default Laborsaber;
