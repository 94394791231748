import { useFormik } from 'formik';
import moment from 'moment';
import { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import DateRangePicker from 'rsuite/DateRangePicker';

import {
    GetOneShift,
    GetShiftCategories,
    GetShiftReasons,
    PostShifts,
    PutOneShift,
} from 'core/API/shifts';
import Loading from 'core/components/Loading/Loading';
import CenterModal from 'core/components/Modal/CenterModal';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import useModal from 'core/hooks/useModal';
import { useAuth } from 'module/auth/context/AuthContext';
import { IShiftsPost } from 'module/schedule/interfaces/ShiftsInterface';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { generateTimeArray, generateTimeString } from 'shared/utils/dates';
import Buttons from 'styles/buttons.module.scss';
// CSS modules
import Forms from 'styles/forms.module.scss';

import SaveChangesDialog from '../SaveChangesDialog/SaveChangesDialog';

interface ITimeOffRequestModal {
    closeModal: () => void;
    requestData?: any;
    role: boolean;
}

interface IFormikRequest {
    date_start_at: string;
    date_end_at: string;
    start_at: string;
    end_at: string;
    note: string;
    checked: boolean;
    category: string;
    reason: string;
}

declare global {
    interface Date {
        addDays(days?: number): Date;
    }
}

Date.prototype.addDays = function (days: any) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

function getDates(startDate: any, stopDate: any) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

const TimeOffRequestModal: FC<ITimeOffRequestModal> = (props) => {
    const { workkplaceID, timezone } = useWorkplacesContext();
    const { showToast } = useToastContext();
    const auth = useAuth();

    const { open: isModalOpen, openModal, closeModal } = useModal();

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const { data: requestData, isLoading: shiftLoading } = useQuery(
        ['time-off-shift', props?.requestData?.shift_id],
        () => GetOneShift(props?.requestData?.shift_id),
        {
            enabled: props?.role,
        }
    );

    const { data: shiftCategoryList } = useQuery(
        ['shift-category'],
        GetShiftCategories,
        {
            enabled: !props?.role,
        }
    );

    const { data: shiftReasonsList } = useQuery(
        ['shift-reasons'],
        GetShiftReasons,
        {
            enabled: !props?.role,
        }
    );

    const { mutateAsync } = useMutation(PostShifts, {
        onSuccess: () => {
            showToast('success', 'Request successfully added!');
            props.closeModal();
        },
        onError: () => {
            showToast('error');
        },
    });

    const formik = useFormik<IFormikRequest>({
        initialValues: {
            date_start_at: '',
            date_end_at: '',
            start_at: '00:00',
            end_at: '23:59',
            note: requestData?.note || '',
            checked: true,
            category: '0',
            reason: '0',
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const requestObj: IShiftsPost = {
                workplace: {
                    id: workkplaceID,
                },
                time_card_id: 0,
                department: null,
                user: { id: auth.user.id },
                open: true,
                published: false,
                scheduled: false,
                denied: false,
                labor: {
                    wage_hourly_costs: 0,
                    wage_hourly_overtime_costs: 0,
                    scheduled_hours: 0,
                },
                start_at: generateTimeString(
                    values.date_start_at,
                    values.start_at,
                    values.checked,
                    true
                ),
                end_at: generateTimeString(
                    values.date_end_at,
                    values.end_at,
                    values.checked,
                    false
                ),
                note: formik.values.note || '',
                shift_type: {
                    id: 2,
                },
                reason: { id: parseInt(formik.values.reason) },
                category: { id: parseInt(formik.values.category) },
                predicted: true,
                role: null,
            };

            const from = new Date(
                moment(values.date_start_at).format('YYYY/MM/DD')
            );
            const to = new Date(
                moment(values.date_end_at).format('YYYY/MM/DD')
            );

            const allWeeks = getDates(from, to);

            const fmt = 'MM/DD/YYYY HH:mm:ss';

            allWeeks.forEach(async (time, index) => {
                let startShift = moment(time).format(fmt);
                let endShift = moment(time).endOf('day').format(fmt);
                const copyObject = { ...requestObj };

                if (index === 0) {
                    startShift = moment(time)
                        .set('hours', +values.start_at.split(':')[0])
                        .set('minutes', +values.start_at.split(':')[1])
                        .format(fmt);
                } else if (index === allWeeks.length - 1) {
                    endShift = moment(time)
                        .set('hours', +values.end_at.split(':')[0])
                        .set('minutes', +values.end_at.split(':')[1])
                        .format(fmt);
                }

                copyObject.start_at = moment
                    .tz(startShift, fmt, timezone)
                    .toDate()
                    .toISOString();
                copyObject.end_at = moment
                    .tz(endShift, fmt, timezone)
                    .toDate()
                    .toISOString();

                await mutateAsync(copyObject, {
                    onSuccess: () => {
                        console.log('Success');
                    },
                });
            });
            //mutate(requestObj);
        },
    });

    const { mutate: updateOneShift, isLoading: updateShiftLoading } =
        useMutation(PutOneShift);

    const approveHandler = () => {
        if (props.requestData.overlap) {
            openModal();
        } else {
            updateShift();
        }
    };

    const updateShift = () => {
        const { id, time_card_id, ...rest } = requestData!;
        updateOneShift(
            {
                id,
                body: {
                    ...rest,
                    scheduled: true,
                    note: formik.values.note,
                    time_card_id: parseInt(time_card_id),
                },
            },
            {
                onSuccess: () => {
                    showToast('success', 'Request aproved!');
                    props.closeModal();
                },
                onError: () => {
                    showToast('error');
                    props.closeModal();
                },
            }
        );
    };

    const denyHandler = () => {
        const { id, time_card_id, ...rest } = requestData!;
        updateOneShift(
            {
                id,
                body: {
                    ...rest,
                    denied: true,
                    note: formik.values.note,
                    time_card_id: parseInt(time_card_id),
                },
            },
            {
                onSuccess: () => {
                    showToast('success', 'Request denied!');
                    props.closeModal();
                },
                onError: () => {
                    showToast('error');
                    props.closeModal();
                },
            }
        );
    };

    return shiftLoading ? (
        <Loading />
    ) : (
        <>
            {!props.role ? (
                <div className={Forms.formGroupSplit}>
                    {/* We will not need this for time off request as its not possible to request less than whole day, leaving this if client wants this feature back */}
                    {/* <label className={Forms.formLabel} htmlFor="wholeDay">
                        Whole day
                    </label>
                    <div className={Forms.formToggle}>
                        <input
                            type="checkbox"
                            id="wholeDay"
                            name="checked"
                            defaultChecked={formik.values.checked}
                            onChange={formik.handleChange}
                        />
                    </div> */}
                </div>
            ) : (
                <div className={Forms.formGroup}>
                    <p className={Forms.formLabel}>Employee who made request</p>
                    <p className={Forms.formLockedText}>
                        {requestData?.user.first_name}{' '}
                        {requestData?.user.last_name}
                    </p>
                </div>
            )}
            {!props.role ? (
                <div className={Forms.formGroup}>
                    <p className={Forms.formLabel}>Select date</p>
                    <DateRangePicker
                        showOneCalendar
                        format="MM/dd/yyyy"
                        isoWeek={true}
                        ranges={[
                            {
                                label: 'today',
                                value: [new Date(), new Date()],
                            },
                            {
                                label: 'Next 7 days',
                                value: [
                                    moment().toDate(),
                                    moment().add(7, 'days').toDate(),
                                ],
                            },
                        ]}
                        onChange={(value) => {
                            if (value) {
                                formik.setFieldValue(
                                    'date_start_at',
                                    moment(value[0]).format('MM/DD/YYYY')
                                );
                                formik.setFieldValue(
                                    'date_end_at',
                                    moment(value[1]).format('MM/DD/YYYY')
                                );
                            }
                        }}
                        onClean={() => {
                            formik.setFieldValue('date_start_at', '');
                            formik.setFieldValue('date_end_at', '');
                        }}
                    />
                </div>
            ) : (
                <div className={Forms.formGroupSplit}>
                    <div className={Forms.formGroup}>
                        <p className={Forms.formLabel}>Start date</p>
                        <p className={Forms.formLockedText}>
                            {moment
                                .utc(requestData?.start_at)
                                .format('dddd, D MMMM YYYY')}
                        </p>
                    </div>
                    <div className={Forms.formGroup}>
                        <p className={Forms.formLabel}>End date</p>
                        <p className={Forms.formLockedText}>
                            {moment
                                .utc(requestData?.end_at)
                                .format('dddd, D MMMM YYYY')}
                        </p>
                    </div>
                </div>
            )}
            <div className={Forms.formGroupSplit}>
                <div className={Forms.formGroup}>
                    {!props.role ? (
                        <>
                            <label
                                className={Forms.formLabel}
                                htmlFor="start_at"
                            >
                                From
                            </label>
                            <select
                                className={Forms.formSelect}
                                name="start_at"
                                id="start_at"
                                value={formik.values.start_at}
                                onChange={formik.handleChange}
                                disabled={formik.values.checked}
                            >
                                {generateTimeArray().map((value, index) => (
                                    <option value={value} key={index}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </>
                    ) : (
                        <>
                            <p className={Forms.formLabel}>From</p>
                            <p className={Forms.formLockedText}>
                                {moment
                                    .tz(requestData.start_at, timezone)
                                    .format('H:mm')}
                            </p>
                        </>
                    )}
                </div>
                <div className={Forms.formGroup}>
                    {!props.role ? (
                        <>
                            <label className={Forms.formLabel} htmlFor="end_at">
                                To
                            </label>
                            <select
                                className={Forms.formSelect}
                                name="end_at"
                                id="end_at"
                                value={formik.values.end_at}
                                onChange={formik.handleChange}
                                disabled={formik.values.checked}
                            >
                                {generateTimeArray(formik.values.start_at).map(
                                    (value, index) => (
                                        <option value={value} key={index}>
                                            {value}
                                        </option>
                                    )
                                )}
                            </select>
                        </>
                    ) : (
                        <>
                            <p className={Forms.formLabel}>To</p>
                            <p className={Forms.formLockedText}>
                                {moment
                                    .tz(requestData.end_at, timezone)
                                    .format('H:mm')}
                            </p>
                        </>
                    )}
                </div>
            </div>

            <div className={Forms.formGroup}>
                {!props.role ? (
                    <>
                        <label className={Forms.formLabel} htmlFor="category">
                            Category
                        </label>
                        <select
                            className={Forms.formSelect}
                            name="category"
                            id="category"
                            value={formik.values.category}
                            onChange={formik.handleChange}
                        >
                            <option value="0" disabled>
                                Select category
                            </option>
                            {shiftCategoryList?.items.map((category) => (
                                <option value={category.id} key={category.id}>
                                    {category.label}
                                </option>
                            ))}
                        </select>
                    </>
                ) : (
                    <>
                        <p className={Forms.formLabel}>Category</p>
                        <p className={Forms.formLockedText}>
                            {requestData?.category.label}
                        </p>
                    </>
                )}
            </div>

            <div className={Forms.formGroup}>
                {!props.role ? (
                    <>
                        <label className={Forms.formLabel} htmlFor="reason">
                            Reason
                        </label>
                        <select
                            className={Forms.formSelect}
                            name="reason"
                            id="reason"
                            value={formik.values.reason}
                            onChange={formik.handleChange}
                        >
                            <option value="0">Choose reason</option>
                            {shiftReasonsList?.items.map((reason) => (
                                <option value={reason.id} key={reason.id}>
                                    {reason.label}
                                </option>
                            ))}
                        </select>
                    </>
                ) : (
                    <>
                        <p className={Forms.formLabel}>Reason</p>
                        <p className={Forms.formLockedText}>
                            {requestData?.reason.label}
                        </p>
                    </>
                )}
            </div>
            <div className={Forms.formGroup}>
                <label className={Forms.formLabel} htmlFor="textarea">
                    Note
                </label>
                <textarea
                    className={Forms.formTextarea}
                    name="note"
                    id="textarea"
                    placeholder={
                        !props.role
                            ? 'Leave a note for your manager'
                            : 'Leave a note for employee'
                    }
                    maxLength={160}
                    value={formik.values.note}
                    onChange={formik.handleChange}
                ></textarea>
            </div>
            {!props.role ? (
                <div className={Forms.formFooter}>
                    <button
                        className={Buttons.btnText}
                        onClick={() => setIsDialogOpen(true)}
                    >
                        Cancel
                    </button>
                    <button
                        className={Buttons.btnPrimary}
                        onClick={() => formik.handleSubmit()}
                        type="submit"
                    >
                        Save request
                    </button>
                </div>
            ) : (
                <div className={Forms.formFooterSplit}>
                    {updateShiftLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <button
                                className={Buttons.btnSuccess}
                                onClick={approveHandler}
                            >
                                Approve
                            </button>
                            <button
                                className={Buttons.btnError}
                                onClick={denyHandler}
                            >
                                Deny
                            </button>
                        </>
                    )}
                </div>
            )}
            {isDialogOpen && (
                <SaveChangesDialog
                    isSubmitting={formik.isSubmitting}
                    save={formik.handleSubmit}
                    dontsave={props.closeModal}
                    goback={setIsDialogOpen}
                />
            )}

            {isModalOpen ? (
                <CenterModal
                    title="Conflict warning"
                    close={() => {
                        closeModal();
                    }}
                    render={() => (
                        <div>
                            <h5>
                                If accepted, this shift will cause a conflict
                                with an already existing shift on the same user
                            </h5>
                            <div className={Forms.formFooterSplit}>
                                <button
                                    className={Buttons.btnSuccess}
                                    onClick={updateShift}
                                >
                                    Continue
                                </button>
                                <button
                                    className={Buttons.btnError}
                                    onClick={closeModal}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    )}
                />
            ) : null}
        </>
    );
};

export default TimeOffRequestModal;
