import { FC } from 'react';

import { PrioritiesEnum } from '../../enums';

import lowPriority from 'assets/icons/LowPriorityIcon.svg';
import mediumPriority from 'assets/icons/MediumPriorityIcon.svg';
import highPriority from 'assets/icons/HighPriorityIcon.svg';

import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Alerts from './AlertType.module.scss';
interface IAlertType {
    currMaxPriority: string;
}

const AlertType: FC<IAlertType> = ({ currMaxPriority }) => {
    const displayAlertType = () => {
        switch (currMaxPriority) {
            case PrioritiesEnum.LOW:
                return (
                    <>
                        <SvgIcon
                            spriteUrl={lowPriority}
                            className={Alerts.alertsIconLow}
                        />
                        <p className={Alerts.alertsTypeTitle}>
                            No correctible actions are needed.
                        </p>
                    </>
                );
            case PrioritiesEnum.MEDIUM:
                return (
                    <>
                        <SvgIcon
                            spriteUrl={mediumPriority}
                            className={Alerts.alertsIconMedium}
                        />
                        <p className={Alerts.alertsTypeTitle}>
                            Correctible actions needed until the end of the day!
                        </p>
                    </>
                );
            case PrioritiesEnum.HIGH:
                return (
                    <>
                        <SvgIcon
                            spriteUrl={highPriority}
                            className={Alerts.alertsIconHigh}
                        />
                        <p className={Alerts.alertsTypeTitle}>
                            Correctible actions needed now!
                        </p>
                    </>
                );
            case PrioritiesEnum.URGENT:
                return (
                    <>
                        <SvgIcon
                            spriteUrl={highPriority}
                            className={Alerts.alertsIconHigh}
                        />
                        <p className={Alerts.alertsTypeTitle}>
                            Correctible actions needed now!
                        </p>
                    </>
                );
            default:
                return (
                    <>
                        <SvgIcon
                            spriteUrl={lowPriority}
                            className={Alerts.alertsIconLow}
                        />
                        <p className={Alerts.alertsTypeTitle}>
                            No correctible actions are needed.
                        </p>
                    </>
                );
        }
    };

    return (
        <article className={Alerts.alertsWrapper}>{displayAlertType()}</article>
    );
};

export default AlertType;
