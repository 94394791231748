import {
    CalendarNav,
    CalendarNext,
    CalendarPrev,
    CalendarToday,
    Eventcalendar,
    MbscCalendarEvent,
    MbscCalendarEventData,
    MbscEventcalendarView,
    setOptions,
    momentTimezone,
} from '@mobiscroll/react';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import React from 'react';

import momentTZ from 'moment-timezone';

// setup Mobiscroll Moment plugin
momentTimezone.moment = momentTZ;

interface IMobilePage {
    updateTimeRange: (firstday: Date, lastday: Date) => void;
    myEvents: MbscCalendarEvent[];
    handleOpenShiftClick: (args: number) => void;
    handleTradePickingShift: (args: any) => void;
}

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

export default function MobilePage(props: IMobilePage): JSX.Element {
    const { timezone } = useWorkplacesContext();

    const view = React.useMemo<MbscEventcalendarView>(() => {
        return {
            calendar: {
                type: 'week',
                startDay: 1,
                endDay: 0,
            },
            agenda: { type: 'day' },
        };
    }, []);
    const renderMyHeader = () => {
        return (
            <React.Fragment>
                <div className="customHeader">
                    <div className="customHeaderTop">
                        <CalendarNav className="md-work-week-nav" />

                        <div>
                            <CalendarPrev className="md-work-week-prev" />
                            <CalendarToday className="md-work-week-today" />
                            <CalendarNext className="md-work-week-next" />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const renderEventContent = React.useCallback<
        (data: MbscCalendarEventData) => any
    >((data: MbscCalendarEventData) => {
        const original = data.original!;
        return (
            <div>
                <div
                    className={`mobileCustomEvent ${
                        data.title === 'Open shift' ? 'mobileOpenShift' : null
                    }`}
                >
                    <div className="mobileShift">
                        <div>{data.title}</div>
                        <p>{original.role ?? 'Time off'}</p>
                    </div>
                </div>
            </div>
        );
    }, []);

    const orderMyEvents = React.useCallback(
        (firstEvent, secondEvent) => {
            const firstEventUserLoggedIn = firstEvent.owner; // true/false
            const secondEventUserLoggedIn = secondEvent.owner; // true/false
            let roleCompare = undefined;
            if (firstEvent?.role && secondEvent?.role) {
                roleCompare = firstEvent?.role.localeCompare(secondEvent?.role); // 1/0/-1
            }
            const firstEventStart = new Date(firstEvent.start).getTime(); // number
            const secondEventStart = new Date(secondEvent.start).getTime(); // number
            //username
            //const firstEventUser = ...; // string
            //const secondEventUser = ...; // string
            const titleA = firstEvent.title.split(' ');
            const titleB = secondEvent.title.split(' ');
            if (firstEventUserLoggedIn !== secondEventUserLoggedIn) {
                return firstEventUserLoggedIn ? -1 : 1;
            } else {
                if (roleCompare !== 0) {
                    return roleCompare;
                } else {
                    if (firstEventStart !== secondEventStart) {
                        return firstEventStart - secondEventStart;
                    } else {
                        return titleB[titleB.length - 1].localeCompare(
                            titleA[titleA.length - 1]
                        );
                    }
                }
            }
        },
        [props.myEvents]
    );

    return (
        <div>
            <Eventcalendar
                theme="ios"
                themeVariant="light"
                dataTimezone={timezone}
                displayTimezone={timezone}
                timezonePlugin={momentTimezone}
                data={props.myEvents}
                firstDay={1}
                view={view}
                eventOrder={orderMyEvents}
                renderHeader={renderMyHeader}
                renderEventContent={renderEventContent}
                onPageChange={(event) => {
                    /* Your custom event handler goes here */

                    props.updateTimeRange(event.firstDay, event.lastDay);
                }}
                onEventClick={(event) => {
                    if (event.event.isOpenShift) {
                        props.handleOpenShiftClick(event.event.shift_id);
                    } else if (event.event.isTradeSelected) {
                        props.handleTradePickingShift(event.event);
                    }
                }}
            />
        </div>
    );
}
