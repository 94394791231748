import { GetWorkplaceList } from 'core/API/workplaces';
import { getTokenFromLocalStorage } from 'core/services/axiosInterceptors';
import { useAuth } from 'module/auth/context/AuthContext';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { IWorkplaces } from '../interface/WorkplacesInterface';

interface IRQuery {
    error: null;
    isError: false;
    isIdle: true;
    isLoading: false;
    isLoadingError: false;
    isRefetchError: false;
    isSuccess: false;
    status: 'idle';
    dataUpdatedAt: number;
    errorUpdatedAt: number;
}

interface IQuery extends IRQuery {
    data: IWorkplaces;
    workkplaceID: number;
    timezone: string;
    setWorkplaceIDHandler: (arg0: number, arg1: string) => void;
}

const WorkplacesContext = React.createContext<any>(undefined!);

export function WorkplacesContextProvider({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    const auth = useAuth();
    const { ...rest } = useQuery(['workplaces'], GetWorkplaceList, {
        enabled: auth.isLoggedin(),
        staleTime: Infinity,
    });

    const { workkplaceID, setWorkplaceIDHandler, timezone } =
        useProviderFucntions();
    return (
        <WorkplacesContext.Provider
            value={{
                ...rest,
                workkplaceID,
                timezone,
                setWorkplaceIDHandler,
            }}
        >
            {children}
        </WorkplacesContext.Provider>
    );
}

function useProviderFucntions() {
    const [workkplaceID, setWorkkplaceID] = useState<number>(0);
    const [timezone, setTimezone] = useState<string>('');

    useEffect(() => {
        setUserFromLocaleStorage();
    }, []);

    const setUserFromLocaleStorage = () => {
        const workplaceID = getTokenFromLocalStorage('workplace');
        const timezoneLS = getTokenFromLocalStorage('timezone');
        setWorkkplaceID(parseInt(workplaceID));
        setTimezone(timezoneLS);
    };

    const setWorkplaceIDHandler = async (id: number, timezone: string) => {
        setTimeout(async () => {
            console.log(timezone);
            const data = await JSON.parse(localStorage.getItem('BYOD-app')!);
            data.workplace = +id;
            data.timezone = timezone;
            localStorage.setItem('BYOD-app', JSON.stringify(data));
            setWorkkplaceID(+id);
            setTimezone(timezone);
        }, 50);
    };

    return { workkplaceID, timezone, setWorkplaceIDHandler };
}

export const useWorkplacesContext = (): IQuery =>
    React.useContext(WorkplacesContext);
