import { GetEmployeePerformance } from 'core/API/laborsaber';
import Loading from 'core/components/Loading/Loading';
import { getBusinessDay } from 'module/laborsaber/utils/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { FC } from 'react';
import { useQuery } from 'react-query';

// CSS modules
import Sections from '../sections.module.scss';

const EmployeePerformance: FC = () => {
    const { workkplaceID, timezone } = useWorkplacesContext();

    const { isLoading, data: empData } = useQuery('employeePerformance', () => {
        const [firstBusinessDay, lastBusinessDay] = getBusinessDay(timezone);

        return GetEmployeePerformance({
            workkplaceID,
            formatedFirstDay: firstBusinessDay,
            formatedLastDay: lastBusinessDay,
        });
    });

    return (
        <article
            style={{
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                padding: '24px',
                boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
                background: '#ffffff',
                gridArea: 'emplPerf',
                minHeight: '300px',
            }}
        >
            {isLoading ? (
                <div>
                    <Loading />
                </div>
            ) : (
                <>
                    <h4 style={{ paddingLeft: '16px' }}>
                        Employee performance
                    </h4>
                    <div style={{ display: 'flex', overflow: 'auto' }}>
                        <div className={Sections.table}>
                            <div className={Sections.tableHeader}>
                                <span>Employee</span>
                                <span>Total Sales</span>
                                <span>Avg Tips %</span>
                                <span>Void %</span>
                                <span>Discounts %</span>
                            </div>
                            <div className={Sections.tableBody}>
                                {!empData?.items.length ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minHeight: '300px',
                                        }}
                                    >
                                        No data
                                    </div>
                                ) : null}
                                {empData.items?.map((row) => {
                                    const PRICE = parseFloat(row.price);
                                    const TIP = parseFloat(row.tip);
                                    const VOID_AMOUNT = parseFloat(
                                        row.void_amount
                                    );

                                    const DISCOUNT_AMOUNT = parseFloat(
                                        row.discount_amount
                                    );

                                    const calculateTip = (TIP / PRICE) * 100;
                                    const calculateAmount =
                                        (VOID_AMOUNT / PRICE) * 100;
                                    const calculateDiscount =
                                        (DISCOUNT_AMOUNT / PRICE) * 100;

                                    return (
                                        <div
                                            key={row.employee_id}
                                            className={Sections.tableRow}
                                        >
                                            <div>
                                                {row.first_name} {row.last_name}
                                            </div>
                                            <div>${PRICE.toFixed(2)}</div>
                                            <div>
                                                {calculateTip.toFixed(2) || 0}%
                                            </div>
                                            <div>
                                                {calculateAmount.toFixed(2) ||
                                                    0}
                                                %
                                            </div>
                                            <div>
                                                {calculateDiscount.toFixed(2) ||
                                                    0}
                                                %
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </article>
    );
};

export default EmployeePerformance;
