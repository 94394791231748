const generateDatesForNextWeeks = (
    startDate: string,
    numOfWeeks: number
): Date[] => {
    const start = new Date(startDate);
    const days = 7 - start.getDay() + numOfWeeks * 7;
    const dates = [];

    for (let i = 0; i < days; i++) {
        dates.push(new Date(start.getTime() + i * 1000 * 60 * 60 * 24));
    }

    return dates;
};

export default generateDatesForNextWeeks;
