import { MbscCalendarEvent } from '@mobiscroll/react';
import { useFormik } from 'formik';
import { utc } from 'moment';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { GetOneShift, GetShiftCategories, PutOneShift } from 'core/API/shifts';
import Loading from 'core/components/Loading/Loading';
import CenterModal from 'core/components/Modal/CenterModal';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import useModal from 'core/hooks/useModal';
import { IUsers, Role } from 'core/interfaces/userInterface';
import { useAuth } from 'module/auth/context/AuthContext';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { formatTimeWithtimezone } from 'shared/utils/dates/formatTimeWithTimezone';
// CSS modules
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';

import SaveChangesDialog from '../SaveChangesDialog/SaveChangesDialog';

interface ICoverRequestModal {
    closeModal: () => void;
    requestData?: any;
    role: boolean;
    shifts?: MbscCalendarEvent[];
}

const CoverRequestModal: FC<ICoverRequestModal> = (props) => {
    const queryClient = useQueryClient();
    const auth = useAuth();
    const { showToast } = useToastContext();

    const { open: isModalOpen, openModal, closeModal } = useModal();

    const { workkplaceID, timezone } = useWorkplacesContext();

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [shiftDate, setShiftDate] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<IUsers | null>(null);

    const { data: shiftCategoryList } = useQuery(
        ['shift-category'],
        GetShiftCategories,
        {
            enabled: !props?.role,
        }
    );

    const usersOnThisWorkplace = queryClient.getQueryData<IUsers[]>([
        'users',
        workkplaceID,
    ]);

    const { data: requestData, isLoading: shiftLoading } = useQuery(
        ['cover-shift', props?.requestData?.shift_id],
        () => GetOneShift(props.requestData.shift_id!),
        {
            enabled: props?.role,
            onSuccess: (data) => {
                const shiftDateString = `${utc(data.start_at).format(
                    'dddd, D MMMM YYYY, H:mm A'
                )} - ${utc(data.end_at).format('H:mm A')}`;

                setShiftDate(shiftDateString);
            },
        }
    );

    const { mutate: updateOneShift, isLoading: updateShiftLoading } =
        useMutation(PutOneShift);

    const formik = useFormik<any>({
        initialValues: {
            newUserID: 0,
            note: props.role
                ? `${requestData?.note}`
                : `${auth.user.first_name} ${auth.user.last_name} (${auth.user.email})`,
            shiftID: 0,
            category: 0,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const { id, time_card_id, ...rest } = getOneShiftData!;
            updateOneShift(
                {
                    id,
                    body: {
                        ...rest,
                        user: { id: values.newUserID },
                        note: values.note,
                        // note: `${auth.user.first_name} ${auth.user.last_name} (${auth.user.email}}`,
                        scheduled: false,
                        shift_type: { id: 5 },
                        category: { id: formik.values.category },
                        time_card_id: parseInt(time_card_id),
                    },
                },
                {
                    onSuccess: () => {
                        showToast('success', 'Request successfully added!');
                        props.closeModal();
                    },
                    onError: () => {
                        showToast('error');
                        props.closeModal();
                    },
                }
            );
        },
    });

    const { data: getOneShiftData } = useQuery(
        ['cover-shift', formik.values.shiftID],
        () => GetOneShift(formik.values.shiftID),
        {
            enabled: !!formik.values.shiftID,
            retry: 1,

            onError: (e: any) => {
                props.closeModal();
                showToast('error', `${e?.error}`);
            },
        }
    );

    const chooseUserHandler = (e: any) => {
        formik.handleChange(e);

        const user = usersOnThisWorkplace?.filter((user: IUsers) => {
            if (parseInt(e.target.value) === user.user.id) {
                return user;
            }
        })[0];

        setSelectedUser(user!);
    };

    const approveHandler = () => {
        if (props.requestData.overlap) {
            openModal();
        } else {
            updateShift();
        }
    };

    const updateShift = () => {
        const { id, time_card_id, ...rest } = requestData!;
        updateOneShift(
            {
                id,
                body: {
                    ...rest,
                    scheduled: true,
                    note: formik.values.note,
                    time_card_id: parseInt(time_card_id), //  idk
                },
            },
            {
                onSuccess: () => {
                    showToast('success', 'Request aproved!');
                    props.closeModal();
                },
                onError: () => {
                    showToast('error');
                    props.closeModal();
                },
            }
        );
    };

    const denyHandler = () => {
        const { id, time_card_id, ...rest } = requestData!;
        updateOneShift(
            {
                id,
                body: {
                    ...rest,
                    denied: true,
                    time_card_id: parseInt(time_card_id),
                },
            },
            {
                onSuccess: () => {
                    showToast('success', 'Request denied!');
                    props.closeModal();
                },
                onError: () => {
                    showToast('error');
                    props.closeModal();
                },
            }
        );
    };

    return shiftLoading ? (
        <Loading />
    ) : (
        <>
            {!props?.role ? (
                <>
                    <div className={Forms.formGroup}>
                        <label className={Forms.formLabel} htmlFor="newUserID">
                            Who would you ask for cover?
                        </label>
                        <select
                            className={Forms.formSelect}
                            name="newUserID"
                            id="newUserID"
                            value={formik.values.newUserID}
                            onChange={chooseUserHandler}
                        >
                            <option value="0" disabled>
                                Choose employee
                            </option>
                            {usersOnThisWorkplace &&
                                usersOnThisWorkplace.map((user: IUsers) => {
                                    let optionValue: any;
                                    user.role.forEach((role: Role) => {
                                        auth.role?.forEach((userRole: Role) => {
                                            if (role.id === userRole.id) {
                                                optionValue = (
                                                    <option
                                                        value={user.user.id}
                                                        key={
                                                            role.id +
                                                            userRole.id *
                                                                Math.random()
                                                        }
                                                    >
                                                        {user.user.first_name}{' '}
                                                        {user.user.last_name}
                                                    </option>
                                                );
                                            }
                                        });
                                    });

                                    return optionValue;
                                })}
                        </select>
                    </div>
                    <div className={Forms.formGroup}>
                        <label className={Forms.formLabel} htmlFor="category">
                            Category
                        </label>
                        <select
                            className={Forms.formSelect}
                            name="category"
                            id="category"
                            value={formik.values.workplace}
                            onChange={formik.handleChange}
                        >
                            <option value="0" disabled>
                                Choose category
                            </option>

                            {shiftCategoryList?.items.map((category) => (
                                <option value={category.id} key={category.id}>
                                    {category.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={Forms.formGroup}>
                        <label className={Forms.formLabel} htmlFor="shiftID">
                            Choose shift
                        </label>
                        <select
                            className={Forms.formSelect}
                            name="shiftID"
                            id="shiftID"
                            value={formik.values.shiftID}
                            onChange={formik.handleChange}
                        >
                            <option value="0" disabled>
                                Choose shift
                            </option>

                            {props.shifts?.map((shift, index) => {
                                console.log(shift);
                                if (shift.scheduled)
                                    if (shift.resource === auth.user.id) {
                                        let optionValue: any;

                                        selectedUser?.role.map((role: Role) => {
                                            if (role.name === shift.role) {
                                                optionValue = (
                                                    <option
                                                        value={shift.shift_id}
                                                        key={
                                                            index +
                                                            +shift.id! *
                                                                Math.random()
                                                        }
                                                    >{`${
                                                        shift.role
                                                    } - ${formatTimeWithtimezone(
                                                        shift.start,
                                                        timezone,
                                                        'ddd, D MM YYYY, HH:mm'
                                                    )} - ${formatTimeWithtimezone(
                                                        shift.end,
                                                        timezone,
                                                        'HH:mm'
                                                    )}`}</option>
                                                );
                                            }
                                        });
                                        return optionValue;
                                    }
                            })}
                        </select>
                    </div>
                </>
            ) : (
                <>
                    <div className={Forms.formGroup}>
                        <p className={Forms.formLabel}>
                            Employee who made request
                        </p>
                        <p className={Forms.formLockedText}>
                            {requestData?.note}
                        </p>
                    </div>
                    <div className={Forms.formGroup}>
                        <p className={Forms.formLabel}>When</p>
                        <p className={Forms.formLockedText}>{shiftDate}</p>
                    </div>
                    <div className={Forms.formGroup}>
                        <p className={Forms.formLabel}>
                            Who should cover shift
                        </p>
                        <p className={Forms.formLockedText}>
                            {requestData?.user.first_name}{' '}
                            {requestData?.user.last_name}
                        </p>
                    </div>
                    <div className={Forms.formGroup}>
                        <p className={Forms.formLabel}>Category</p>
                        <p className={Forms.formLockedText}>
                            {requestData?.category?.label}
                        </p>
                    </div>
                </>
            )}
            <div className={Forms.formGroup}>
                <label className={Forms.formLabel} htmlFor="textarea">
                    Note
                </label>
                <textarea
                    className={Forms.formTextarea}
                    name="note"
                    id="textarea"
                    placeholder={
                        !props?.role
                            ? 'Leave a note for your manager'
                            : 'Leave a note for employee'
                    }
                    maxLength={160}
                    value={formik.values.note}
                    onChange={formik.handleChange}
                ></textarea>
            </div>
            {!props?.role ? (
                <div className={Forms.formFooter}>
                    <button
                        className={Buttons.btnText}
                        onClick={() => setIsDialogOpen(true)}
                    >
                        Cancel
                    </button>
                    <button
                        className={Buttons.btnPrimary}
                        onClick={() => formik.handleSubmit()}
                        type="submit"
                    >
                        Save request
                    </button>
                </div>
            ) : (
                <div className={Forms.formFooterSplit}>
                    {updateShiftLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <button
                                className={Buttons.btnSuccess}
                                onClick={approveHandler}
                            >
                                Approve
                            </button>
                            <button
                                className={Buttons.btnError}
                                onClick={denyHandler}
                            >
                                Deny
                            </button>
                        </>
                    )}
                </div>
            )}
            {isModalOpen ? (
                <CenterModal
                    title="Conflict warning"
                    close={() => {
                        closeModal();
                    }}
                    render={() => (
                        <div>
                            <h5>
                                If accepted, this shift will cause a conflict
                                with an already existing shift on the same user
                            </h5>
                            <div className={Forms.formFooterSplit}>
                                <button
                                    className={Buttons.btnSuccess}
                                    onClick={updateShift}
                                >
                                    Continue
                                </button>
                                <button
                                    className={Buttons.btnError}
                                    onClick={closeModal}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    )}
                />
            ) : null}
            {isDialogOpen && (
                <SaveChangesDialog
                    isSubmitting={formik.isSubmitting}
                    save={formik.handleSubmit}
                    dontsave={props.closeModal}
                    goback={setIsDialogOpen}
                />
            )}
        </>
    );
};

export default CoverRequestModal;
