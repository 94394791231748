import { FC, useState } from 'react';

// CSS modules
import Sections from '../sections.module.scss';
import Loading from 'core/components/Loading/Loading';
import { useQuery } from 'react-query';
import { GetEmployeeAttendance } from 'core/API/laborsaber';
import moment from 'moment';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { getBusinessDay } from 'module/laborsaber/utils/utils';

const EmployeeClockEntries: FC = () => {
    const { workkplaceID, timezone } = useWorkplacesContext();

    const [tableList, setTableList] = useState([]);

    // query

    const { isLoading } = useQuery(
        'EmployeeAttendance',
        () => {
            const [firstBusinessDay, lastBusinessDay] =
                getBusinessDay(timezone);

            return GetEmployeeAttendance({
                workkplaceID,
                formatedFirstDay: firstBusinessDay,
                formatedLastDay: lastBusinessDay,
            });
        },
        {
            onSuccess: (data) => {
                const tempArr = [];

                data.items.forEach((row) => {
                    if (
                        row.employee_arrived !== null ||
                        row.employee_left !== null
                    ) {
                        const timeArrived = moment
                            .tz(row.shift.start_at, timezone)
                            .diff(
                                moment.tz(row.employee_arrived, timezone),
                                'minutes'
                            );

                        const timeLeft = moment
                            .tz(row.shift.end_at, timezone)
                            .diff(
                                moment.tz(row.employee_left, timezone),
                                'minutes'
                            );

                        if (timeArrived <= 0 && timeArrived <= -10) {
                            tempArr.push({
                                name: `${row.user.first_name} ${row.user.last_name}`,
                                emp_arrived: row.employee_arrived,
                                message: `${Math.abs(
                                    timeArrived
                                )} minutes arrived late`,
                            });
                        } else if (timeArrived >= 0 && timeArrived >= 10) {
                            tempArr.push({
                                name: `${row.user.first_name} ${row.user.last_name}`,
                                emp_arrived: row.employee_arrived,
                                message: `${Math.abs(
                                    timeArrived
                                )} minutes arrived early`,
                            });
                        }

                        if (timeLeft >= 0 && timeLeft >= 10) {
                            tempArr.push({
                                name: `${row.user.first_name} ${row.user.last_name}`,
                                emp_arrived: row.employee_left,
                                message: `${Math.abs(
                                    timeLeft
                                )} minutes left early`,
                            });
                        } else if (timeLeft <= 0 && timeLeft <= -10) {
                            tempArr.push({
                                name: `${row.user.first_name} ${row.user.last_name}`,
                                emp_arrived: row.employee_left,
                                message: `${Math.abs(
                                    timeLeft
                                )} minutes left late`,
                            });
                        }
                    }
                });

                setTableList(tempArr);
            },
        }
    );

    return (
        <article
            style={{
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                padding: '24px',
                boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
                background: '#ffffff',
                gridArea: 'emplClockEntries',
                minHeight: '300px',
            }}
        >
            {isLoading ? (
                <div>
                    <Loading />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <h4 style={{ paddingLeft: '16px' }}>
                            Employee clock-entries
                        </h4>
                        {/* <div className={Buttons.btnToggle}>
                            <button
                                type="button"
                                className={Buttons.btnToggleActive}
                                onClick={() => console.log('All')}
                            >
                                All
                            </button>
                            <button
                                type="button"
                                className={Buttons.btnToggleDefault}
                                onClick={() => console.log('Alerts')}
                            >
                                Alerts
                            </button>
                        </div> */}
                    </div>
                    <div style={{ overflow: 'auto' }}>
                        <div className={Sections.table}>
                            <div className={Sections.tableHeaderThree}>
                                <span>Employee</span>
                                <span>Time</span>
                                <span>Status</span>
                            </div>
                            <div className={Sections.tableBody}>
                                {!tableList.length ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minHeight: '300px',
                                        }}
                                    >
                                        No data
                                    </div>
                                ) : null}
                                {tableList.map((row, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={Sections.tableRowThree}
                                        >
                                            <div>{row.name}</div>
                                            <div>
                                                {moment
                                                    .tz(
                                                        row.emp_arrived,
                                                        timezone
                                                    )
                                                    .format('hh:mm A')}
                                            </div>
                                            <div>{row.message}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </article>
    );
};

export default EmployeeClockEntries;
