import { apiService } from 'core/services/apiService';
import { EmployeeAttendance } from 'module/laborsaber/interface/EmployeeAtendance';
import { EmployeePerformance } from 'module/laborsaber/interface/EmployeePerformance';
import { GraphSales } from 'module/laborsaber/interface/GraphSales';
import { Sales } from 'module/laborsaber/interface/Sales';
import { SituationRoom } from 'module/laborsaber/interface/SituationRoom';

interface iEmployeeProps {
    workkplaceID: number;
    formatedFirstDay: string;
    formatedLastDay: string;
    activeTime?: string;
}

const GetEmployeeAttendance = async ({
    workkplaceID,
    formatedFirstDay,
    formatedLastDay,
}: iEmployeeProps): Promise<EmployeeAttendance> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(
            `employee-attendances?limit=250&workplace=${workkplaceID}&shift_from=${formatedFirstDay}&shift_to=${formatedLastDay}`
        )
    );

    return data;
};

const GetEmployeePerformance = async ({
    workkplaceID,
    formatedFirstDay,
    formatedLastDay,
}: iEmployeeProps): Promise<EmployeePerformance> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(
            `employee-performance?limit=250&workplace=${workkplaceID}&ticket_from=${formatedFirstDay}&ticket_to=${formatedLastDay}`
        )
    );

    return data;
};

const GetGraphSales = async ({
    workkplaceID,
    formatedFirstDay,
    formatedLastDay,
}: iEmployeeProps): Promise<GraphSales> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(
            `labor-saber-sales?limit=250&workplace=${workkplaceID}&sales_from=${formatedFirstDay}&sales_to=${formatedLastDay}&orderBy=timeSlot&direction=ASC`
        )
    );

    return data;
};

const GetSalesInfo = async ({
    workkplaceID,
    formatedFirstDay,
    formatedLastDay,
    activeTime,
}: iEmployeeProps): Promise<Sales> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(
            `labor-saber-sales/daily/labor?limit=250&workplace=${workkplaceID}&time_slot_from=${formatedFirstDay}&time_slot_to=${formatedLastDay}&data_up_to=${activeTime}&billable=1&predicted=1`
        )
    );

    return data;
};

const GetLaborsaborCalculation = async ({
    workkplaceID,
    formatedFirstDay,
    formatedLastDay,
}: iEmployeeProps): Promise<SituationRoom> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(
            `labor-saber-sales/situation-room?limit=250&workplace=${workkplaceID}&from=${formatedFirstDay}&to=${formatedLastDay}`
        )
    );

    return data;
};

export {
    GetEmployeeAttendance,
    GetEmployeePerformance,
    GetGraphSales,
    GetSalesInfo,
    GetLaborsaborCalculation,
};
