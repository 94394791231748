import { GetDepartmentsList } from 'core/API/departments';
import { GetRolesList } from 'core/API/roles';
import { GetUsersList } from 'core/API/users';
import { GetAllWorkplaceList } from 'core/API/workplaces';
import Loading from 'core/components/Loading/Loading';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { FC } from 'react';
import { useQuery } from 'react-query';

const PreloadComp: FC = ({ children }) => {
    const { workkplaceID } = useWorkplacesContext();

    const { isLoading: isLoadingDepartments } = useQuery(
        ['departments'],
        GetDepartmentsList,
        { staleTime: Infinity }
    );
    const { isLoading: isLoadingRoles } = useQuery(['roles'], GetRolesList, {
        staleTime: Infinity,
    });

    const { isLoading: isLoadingWorkplaces } = useQuery(
        ['workplaces-all'],
        GetAllWorkplaceList,
        {
            staleTime: Infinity,
        }
    );

    const { isLoading: isLoadingUsers } = useQuery(
        ['users', workkplaceID],
        () => GetUsersList(workkplaceID),
        {
            enabled: workkplaceID !== 0,
        }
    );

    return (
        <>
            {isLoadingDepartments ||
            isLoadingRoles ||
            isLoadingWorkplaces ||
            isLoadingUsers ? (
                <Loading />
            ) : (
                children
            )}
        </>
    );
};

export default PreloadComp;
