export enum ROUTES {
    NOT_FOUND = '*',
    DEFAULT = '/',
    LOGIN = '/login',
    MANAGER = '/manager',
    EMPLOYEE = `/employee`,
    SITUATION_ROOM = '/manager/situation-room',
    LABORSABER = '/manager/laborsaber',
    GUESTPROTECT = '/guest-protect/:id',
    GUESTCONNECT = '/guest-connect/:workplaceIdentifier/:domainIdentifier',
    GUESTCONNECTSTAFF = '/guest-connect/check/:id',
}
