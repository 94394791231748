import { MbscCalendarEvent } from '@mobiscroll/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import cover from 'assets/icons/Cover.svg';
import customerDb from 'assets/icons/CustomerDB.svg';
import event from 'assets/icons/Event.svg';
import shiftPick from 'assets/icons/ShiftPick.svg';
import timeOff from 'assets/icons/TimeOff.svg';
import trade from 'assets/icons/Trade.svg';
import wavingHand from 'assets/icons/WavingHand.svg';
import { fetchAllEvents } from 'core/API/events';
import { GetSchedules } from 'core/API/schedules';
import { PutOneShift } from 'core/API/shifts';
import Modal from 'core/components/Modal/Modal';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useIsMount } from 'core/hooks/useIsMounted';
import { useMobileDetect } from 'core/hooks/useMobileDetect';
import useModal from 'core/hooks/useModal';
// CSS modules
import useSearchQuery from 'core/hooks/useQuery';
import { IUsers } from 'core/interfaces/userInterface';
import { useAuth } from 'module/auth/context/AuthContext';
import AddEventModal from 'module/schedule/Modals/AddEventModal/AddEventModal';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { IWorkplaces } from 'module/workplaces/interface/WorkplacesInterface';
import getTimeWithTimezone from 'shared/utils/dates/formatTimeWithTimezone';
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';

import Navigation from '../components/Navigation/Navigation';
import { CalendarType, TimeView } from '../interfaces/ScheduleInterface';
import { IShiftGetOne, IShiftsPost } from '../interfaces/ShiftsInterface';
import AddShiftModal from '../Modals/AddShiftModal/AddShiftModal';
import AvailableShiftModal from '../Modals/AvailableShiftModal/AvailableShiftModal';
import CoverRequestModal from '../Modals/CoverRequestModal/CoverRequestModal';
import CustomerDatabaseModal from '../Modals/CustomerDatabase/CustomerDatabaseModal';
import ShiftPickModal from '../Modals/ShiftPickModal/ShiftPickModal';
import TimeOffRequestModal from '../Modals/TimeOffRequestModal/TimeOffRequestModal';
import TradeRequestModal from '../Modals/TradeRequestModal/TradeRequestModal';
import DesktopPage from './DesktopPage/DesktopPage';
import MobilePage from './MobilePage/MobilePage';

import './SchedulePage.style.scss';

export interface ICellClick {
    start_at: Date;
    end_at?: Date;
    resource: number;
    department?: number;
    roles?: number;
}

export default function SchedulePage(): JSX.Element {
    const queryClient = useQueryClient();
    const isMounted = useIsMount();
    const auth = useAuth();

    const { push } = useHistory();

    const { workkplaceID, timezone } = useWorkplacesContext();

    const { showToast } = useToastContext();

    const [editEvent, setEditEvent] = useState(null);
    const [addNewEvent, setAddNewEvent] = useState<ICellClick>();

    const {
        open: isAddShiftModalOpen,
        openModal: openAddShiftModal,
        closeModal: closeAddShiftModal,
    } = useModal();

    const {
        open: isEditShiftModalOpen,
        openModal: openEditShiftModal,
        closeModal: closeEditShiftModal,
    } = useModal();

    const {
        open: isAddEventModalOpen,
        openModal: openAddEventModal,
        closeModal: closeAddEventModal,
    } = useModal();

    const {
        open: isEditEventModalOpen,
        openModal: openEditEventModal,
        closeModal: closeEditEventModal,
    } = useModal();

    const {
        open: isRequestAvailableModalOpen,
        openModal: openRequestAvailableModalOpen,
        closeModal: closeRequestAvailableModalOpen,
    } = useModal();

    const {
        open: isTradeRequestModalOpen,
        openModal: openTradeRequestModal,
        closeModal: closeTradeRequestModal,
    } = useModal();

    const {
        open: isTimeOffRequestModalOpen,
        openModal: openTimeOffRequestModal,
        closeModal: closeTimeOffRequestModal,
    } = useModal();

    const {
        open: isShiftPickModalOpen,
        openModal: openShiftPickModal,
        closeModal: closeShiftPickModal,
    } = useModal();

    const {
        open: isCoverRequestModalOpen,
        openModal: openCoverRequestModal,
        closeModal: closeCoverRequestModal,
    } = useModal();

    const {
        open: isCustomerDatabaseModalOpen,
        openModal: openCustomerDatabaseModal,
        closeModal: closeCustomerDatabaseModal,
    } = useModal();

    // url query string determin what viwe
    const location = useSearchQuery();

    const isValidParam = (paramName: string) => {
        const paramValue = location.get(paramName);
        let isValid = true;
        // console.log('isValidParam', paramValue);
        if (paramValue === 'undefined') {
            isValid = false;
        }
        if (paramValue === 'null') {
            isValid = false;
        }
        if (!paramValue) {
            isValid = false;
        }
        return isValid
            ? location.get(paramName)
            : paramName === 'time'
            ? 'day'
            : 'schedule';
    };

    const [timeView, setView] = useState<TimeView>(
        isValidParam('time') as TimeView
    );

    // for event i have to leave this date state
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [toPublish, setToPublish] = useState(0);
    const [hasConflicts, setHasConflicts] = useState<boolean>(false);
    const [isPublishable, setIsPublishable] = useState<boolean>(false);

    const [allSchedules, setAllSchedules] = useState<MbscCalendarEvent[]>([]);
    const [schedulesBackup, setSchedulesBackup] = useState<MbscCalendarEvent[]>(
        []
    );
    const [schedulesUnassigned, setSchedulesUnassigned] = useState<any>([]);
    const [allEvents, setAllEvents] = useState<MbscCalendarEvent[]>([]);

    const [requestData, setRequestData] = useState(null);

    const [calendarType, setCalendarType] = useState<CalendarType>(
        isValidParam('view') as CalendarType
    );

    const workplacesData = queryClient.getQueryData<IWorkplaces>([
        'workplaces',
    ]);

    const usersData = queryClient.getQueryData<IUsers[]>([
        'users',
        workkplaceID,
    ]);

    const [filters, setFilters] = useState([
        { id: 3, name: 'Pick', active: true },
        { id: 4, name: 'Availability', active: true },
        { id: 2, name: 'Time off', active: true },
        { id: 5, name: 'Cover', active: true },
        { id: 6, name: 'Trade', active: true },
    ]);

    const [showOverlayDropdown, setShowOverlayDropdown] = useState(false);
    const [userListDragDrop, setUserListDragDrop] = useState<IUsers[]>([]);
    const [selectedShift, setselectedShift] = useState<IShiftGetOne | null>(
        null
    );
    const [{ isOver }, dropRef] = useDrop({
        accept: 'shift',
        drop: (item: IShiftGetOne) => {
            const usersList: IUsers[] = [];

            usersData?.forEach((user) => {
                if (
                    user.role.filter((value) => value.id === item.roles[0].id)
                        .length
                ) {
                    usersList.push(user);
                }
            });
            setselectedShift(item);
            setUserListDragDrop(usersList);
            setShowOverlayDropdown(true);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    // useEffect(() => {
    //     console.log('Update view');

    //     const view: CalendarType = isValidParam('view') as CalendarType;
    //     console.log('view', typeof view, view);

    //     const time: TimeView = isValidParam('time') as TimeView;
    //     console.log('time', typeof time, time);

    //     // push(`?view=${view}&time=${time}`);
    //     setView(time);
    //     setCalendarType(view);
    // }, [location.get('view'), location.get('time')]);

    const setCalendarTypeHandler = (view: CalendarType) => {
        console.log('Update calendar view', view);
        // update url query params
        push(`?view=${view}&time=${timeView}`);
        setCalendarType(view);
    };

    const setTimeViewHandler = (time: TimeView) => {
        console.log('Update time view', time);
        // update url query params
        push(`?view=${calendarType}&time=${time}`);
        setView(time);
    };

    useEffect(() => {
        if (toPublish) {
            setIsPublishable(true);
        }
    }, [toPublish, calendarType]);

    const { mutate: updateOneShift, isLoading: loadingUpdateOneShift } =
        useMutation(PutOneShift);

    const { refetch: refetchEvents } = useQuery(
        'events',
        () =>
            fetchAllEvents({
                workplaceID: workkplaceID,
                fromDate: fromDate,
                toDate: toDate,
            }),
        {
            refetchInterval: 600000, // 10 min interval for refresh
            enabled: !!fromDate && !!toDate,
            onSuccess: async (data) => {
                setAllEvents(
                    data?.items?.map((event: any) => {
                        return {
                            ...event,
                            resource: event?.event_type?.id,
                        };
                    })
                );
            },
        }
    );

    const { refetch: refetchSchedules, data: schedules } = useQuery(
        ['schedule', workkplaceID, timeView, calendarType, fromDate, toDate],
        () =>
            GetSchedules({
                workplace: workkplaceID,
                from: fromDate,
                to: toDate,
            }),
        {
            refetchInterval: 600000, // 10 min interval for refresh
            enabled: !!fromDate && !!toDate,
            onSuccess: async (data) => {
                const eventsObj: any = {};
                const customEvents: any = [];
                const unassignedEvents: any = [];
                const requestShifts: any = [];

                // return to default value on every request
                setToPublish(0);
                setHasConflicts(false);
                filterReset(true);

                // sort events for each user
                await data.items.forEach((event) => {
                    if (!event.published && event.shift_type.id === 4) {
                        console.log('event to publish', event);
                        setToPublish((prevCount) => prevCount + 1);
                    }
                    if (event.user === null) {
                        unassignedEvents.push(event);
                    } else {
                        if (eventsObj[event.user.id]) {
                            // add scheduled shift in fron of the array for displaying
                            // right conflicts
                            if (event.scheduled) {
                                eventsObj[event.user.id].unshift(event);
                            } else {
                                eventsObj[event.user.id].push(event);
                            }
                        } else {
                            eventsObj[event.user.id] = [event];
                        }
                    }
                });

                // loop throught every property (user) and its events to see
                // if there is any overlap happening
                for (const property in eventsObj) {
                    // sort dates for easier comparison and so every time last event would be
                    // marked as overlap
                    eventsObj[property].sort((previous: any, current: any) => {
                        // get the start date from previous and current
                        const previousTime = new Date(
                            previous.start_at
                        ).getTime();
                        const currentTime = new Date(
                            current.start_at
                        ).getTime();

                        // if the previous is earlier than the current
                        if (previousTime < currentTime) {
                            return -1;
                        }

                        // if the previous time is the same as the current time
                        if (previousTime === currentTime) {
                            return 0;
                        }

                        // if the previous time is later than the current time
                        return 1;
                    });

                    await eventsObj[property].forEach(
                        (current: any, index: number) => {
                            let overlap = false;
                            // if user has only 1 event
                            if (index) {
                                const previous = eventsObj[property][index - 1];

                                const previousEnd = new Date(
                                    previous.end_at
                                ).getTime();
                                const currentStart = new Date(
                                    current.start_at
                                ).getTime();

                                if (
                                    previousEnd > currentStart
                                    // previous.scheduled &&
                                    // current.scheduled
                                    // comment this out for be able to see overlap in requests
                                ) {
                                    overlap = true;
                                }
                            }

                            if (!current.scheduled) {
                                requestShifts.push({
                                    shift_id: current.id,
                                    id: current?.user?.id + index, // used for key in mobiscroll list
                                    resource: current?.user?.id
                                        ? current?.user?.id
                                        : 0,
                                    start: current.start_at,
                                    end: current.end_at,
                                    title: `${current?.user?.first_name} ${current?.user?.last_name}`,
                                    editable: false, // disable edit option on requests
                                    overlap,
                                    role: current?.roles[0]?.name,
                                    published: current?.published,
                                    shift_type: current.shift_type,
                                    scheduled: current.scheduled,
                                    color: current.roles[0]?.background_color,
                                    background_color:
                                        current.roles[0]?.background_color,
                                    text_color: current.roles[0]?.text_color,
                                    owner: auth.user.id === current?.user?.id,
                                });
                            } else {
                                customEvents.push({
                                    shift_id: current.id,
                                    id: current?.user?.id + index, // used for key in mobiscroll list
                                    resource: current?.user?.id
                                        ? current?.user?.id
                                        : 0,
                                    start: current.start_at,
                                    end: current.end_at,
                                    title: `${current?.user?.first_name} ${current?.user?.last_name}`,
                                    editable: true,
                                    overlap, //
                                    role: current?.roles[0]?.name,
                                    published: current?.published,
                                    scheduled: current.scheduled,
                                    color: current.roles[0]?.background_color,
                                    background_color:
                                        current.roles[0]?.background_color,
                                    text_color: current.roles[0]?.text_color,
                                    owner: auth.user.id === current?.user?.id,
                                    shift_type: current.shift_type,
                                });
                            }
                        }
                    );
                }

                customEvents?.forEach((event: any) => {
                    // check for conflicts
                    if (event.overlap) setHasConflicts(true);
                });

                if (calendarType === 'request') {
                    setAllSchedules(requestShifts);
                    setSchedulesBackup(requestShifts);
                } else {
                    if (useMobileDetect().isMobile()) {
                        const combinedArrayOfEvents = [...customEvents];
                        unassignedEvents.forEach(
                            (shift: any, index: number) => {
                                combinedArrayOfEvents.push({
                                    shift_id: shift.id,
                                    id: shift?.user
                                        ? shift?.user?.id + index
                                        : 0 + index, // used for key in mobiscroll list
                                    resource: shift?.user?.id
                                        ? shift?.user?.id
                                        : 0,
                                    start: shift.start_at,
                                    end: shift.end_at,
                                    title: `Open shift`,
                                    editable: true,
                                    role: shift?.roles[0]?.name,
                                    published: shift?.published,
                                    scheduled: shift.scheduled,
                                });
                            }
                        );
                        setAllSchedules(combinedArrayOfEvents);
                        setSchedulesBackup(combinedArrayOfEvents);
                    } else {
                        setAllSchedules(customEvents);
                        setSchedulesBackup(customEvents);
                        setSchedulesUnassigned(unassignedEvents);
                    }
                }
            },
            onError: (e: any) => {
                showToast('error', e.error);
            },
        }
    );

    useEffect(() => {
        let firstday;
        let lastday;
        if (timeView === 'day' && !useMobileDetect().isMobile()) {
            const today = new Date();

            firstday = new Date(today);
            firstday.setHours(0, 0, 0, 0);

            lastday = new Date(today);
            lastday.setHours(23, 59, 59, 999);
        } else {
            const curr = new Date(); // get current date
            curr.setHours(0, 0, 0, 0);
            const first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week

            firstday = new Date(curr.setDate(first));
            lastday = new Date(firstday);
            lastday.setHours(23, 59, 59, 999);
            lastday.setDate(firstday.getDate() + 6);
        }
        // setFromDate(formatDate(firstday));
        // setToDate(formatDate(lastday));

        console.log('onFirst load', timezone);

        const [formatedFirstDay, formatedLastDay] = getTimeWithTimezone(
            firstday,
            lastday,
            timezone
        );

        setFromDate(formatedFirstDay);
        setToDate(formatedLastDay);
    }, []);

    useEffect(() => {
        if (!isMounted) {
            console.log('REFETCHING CALENDAR ....');
            if (calendarType === 'event') {
                refetchEvents();
            } else {
                refetchSchedules();
            }
        }
    }, [fromDate, toDate]);

    const openEditModal = (event: any) => {
        setEditEvent(event);

        switch (calendarType) {
            case 'schedule':
                openEditShiftModal();
                break;
            case 'event':
                openEditEventModal();
                break;
            default:
                return;
        }
    };

    const openAddModal = (event: any) => {
        setAddNewEvent(undefined);

        if (event.action === 'drag' || event.action === 'externalDrop') {
            setAddNewEvent({
                start_at: event.event.start,
                resource: event.event.resource,
                end_at: event.event.end,
                ...event.event,
            });
        } else {
            setAddNewEvent({ start_at: event.date, resource: event.resource });
        }

        switch (calendarType) {
            case 'schedule':
                openAddShiftModal();
                break;
            case 'event':
                openAddEventModal();
                break;
            default:
                return;
        }
    };

    const openEmptyModalAddNewShift = () => {
        setAddNewEvent(undefined);
        openAddShiftModal();
    };

    const updateTimeRange = (firstday: Date, lastday: Date) => {
        const [formatedFirstDay, formatedLastDay] = getTimeWithTimezone(
            firstday,
            lastday,
            timezone
        );

        setFromDate(formatedFirstDay);
        setToDate(formatedLastDay);

        // check if user is looking in the next week so we can enable
        // publish schedule button for him
        function getFirstDay(d: Date) {
            d = new Date(d);
            const day = d.getDay(),
                diff = d.getDate() - day + (day === 0 ? -6 : 0);
            return new Date(d.setDate(diff));
        }

        const firstDayOfSelectedWeek = getFirstDay(new Date());
        const nextWeekStart = new Date(
            firstDayOfSelectedWeek.getFullYear(),
            firstDayOfSelectedWeek.getMonth(),
            firstDayOfSelectedWeek.getDate() + 7,
            0,
            0,
            0
        );
        const nextWeekEnd = new Date(
            firstDayOfSelectedWeek.getFullYear(),
            firstDayOfSelectedWeek.getMonth(),
            firstDayOfSelectedWeek.getDate() + 14,
            0,
            0,
            0
        );

        if (
            nextWeekStart.getTime() <= firstday.getTime() &&
            firstday.getTime() < nextWeekEnd.getTime()
        ) {
            setIsPublishable(true);
        } else {
            setIsPublishable(false);
        }
    };

    const closeEditModalHandler = () => {
        switch (calendarType) {
            case 'schedule':
                refetchSchedules();
                closeEditShiftModal();
                break;
            case 'event':
                refetchEvents();
                closeEditEventModal();
                break;
            default:
                return;
        }
    };

    const closeAddModalHandler = () => {
        switch (calendarType) {
            case 'schedule':
                refetchSchedules();
                closeAddShiftModal();
                break;
            case 'event':
                refetchEvents();
                closeAddEventModal();
                break;
            default:
                return;
        }
    };

    const openRequestsModal = (event: any): any => {
        setRequestData(event);

        switch (event.shift_type.id) {
            case 2:
                openTimeOffRequestModal();
                break;
            case 3:
                openShiftPickModal();
                break;
            case 4:
                openRequestAvailableModalOpen();
                break;
            case 5:
                openCoverRequestModal();
                break;
            case 6:
                openTradeRequestModal();
                break;
            default:
                return null;
        }
    };

    const filterEvents = () => {
        const newArrOfEvents: MbscCalendarEvent[] = [];
        schedulesBackup.forEach((event) => {
            return filters.forEach((filter) => {
                if (filter.id === event?.shift_type?.id) {
                    if (filter.active) {
                        newArrOfEvents.push(event);
                    }
                }
            });
        });

        setAllSchedules(newArrOfEvents);
    };

    const filterReset = (type: boolean) => {
        const newArr = filters.map((value) => {
            value.active = type;
            return {
                ...value,
            };
        });

        setFilters(newArr);
        filterEvents();
    };

    const handleSelectUserChange = (e: any) => {
        const copyShift: any = { ...selectedShift };
        copyShift.user = { id: parseInt(e.target.value) };
        setselectedShift(copyShift);
    };

    const handleAssignButton = () => {
        const { id, time_card_id, ...rest } = selectedShift!;
        const sendObj: IShiftsPost = {
            ...rest,
            time_card_id: parseInt(time_card_id),
        };

        updateOneShift(
            { id: id, body: sendObj },
            {
                onSuccess: () => {
                    showToast('success', 'Shift assigned successfully!');
                    setShowOverlayDropdown(false);
                    refetchSchedules();
                },
            }
        );
    };

    const reassignShiftToAnotherUser = (newShift: MbscCalendarEvent) => {
        // check for user roles
        usersData?.forEach((user) => {
            if (user.user.id === newShift.resource) {
                if (
                    user.role.filter((role) => role.name === newShift.role)
                        .length
                ) {
                    // user has role update shift
                    schedules?.items.forEach((shift) => {
                        if (shift.id === newShift.shift_id) {
                            const { id, time_card_id, ...rest } = shift;
                            const sendObj: IShiftsPost = {
                                ...rest,
                                user: { id: +newShift.resource! },
                                start_at: moment(newShift.start)
                                    .tz(timezone)
                                    .utc()
                                    .format('YYYY-MM-DDTHH:mm:ss'),
                                end_at: moment(newShift.end)
                                    .tz(timezone)
                                    .utc()
                                    .format('YYYY-MM-DDTHH:mm:ss'),
                                time_card_id: parseInt(time_card_id),
                                predicted: true,
                            };

                            updateOneShift(
                                { id: id, body: sendObj },
                                {
                                    onSuccess: () => {
                                        showToast(
                                            'success',
                                            'Shift assigned successfully!'
                                        );
                                        refetchSchedules();
                                    },
                                    onError: () => {
                                        showToast(
                                            'error',
                                            'Something went wrong!'
                                        );
                                        refetchSchedules();
                                    },
                                }
                            );
                        }
                    });
                } else {
                    // user does not have role show toast and reset board
                    showToast(
                        'error',
                        'User does not have role for this shift!'
                    );
                    refetchSchedules(); // reset board
                }
            }
        });
    };

    const updateOneShiftHandler = (selectedEvent: MbscCalendarEvent) => {
        // find selected shift
        schedules?.items.forEach((shift) => {
            if (shift.id === selectedEvent.shift_id) {
                const { id, time_card_id, ...rest } = shift;
                const sendObj: IShiftsPost = {
                    ...rest,
                    start_at: moment(selectedEvent.start)
                        .tz(timezone)
                        .utc()
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    end_at: moment(selectedEvent.end)
                        .tz(timezone)
                        .utc()
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    time_card_id: parseInt(time_card_id),
                    predicted: true,
                };

                updateOneShift(
                    { id: id, body: sendObj },
                    {
                        onSuccess: () => {
                            showToast(
                                'success',
                                'Shift assigned successfully!'
                            );
                            refetchSchedules();
                        },
                        onError: () => {
                            showToast('error', 'Something went wrong!');
                            refetchSchedules();
                        },
                    }
                );
            }
        });
    };

    return (
        <>
            <section className="Content">
                <div ref={dropRef} className="draggable">
                    {(isOver || showOverlayDropdown) && (
                        <div className="overlay">
                            {showOverlayDropdown ? (
                                <div className="overlayBox">
                                    <div
                                        className="overlayClose"
                                        onClick={() =>
                                            setShowOverlayDropdown(false)
                                        }
                                    >
                                        X
                                    </div>
                                    <div className={Forms.formGroup}>
                                        <label
                                            className={Forms.formLabel}
                                            htmlFor="from"
                                        >
                                            Select user to assign a shift
                                        </label>
                                        <select
                                            className={Forms.formSelect}
                                            name="from"
                                            id="from"
                                            defaultValue=""
                                            onChange={handleSelectUserChange}
                                        >
                                            <option value="" disabled>
                                                Select user
                                            </option>
                                            {userListDragDrop.map((user) => (
                                                <option
                                                    value={user.user.id}
                                                    key={user.user.id}
                                                >
                                                    {user.user.first_name}{' '}
                                                    {user.user.last_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={Forms.formFooter}>
                                        {loadingUpdateOneShift ? (
                                            <ClipLoader
                                                color="#841D80"
                                                loading={true}
                                                size={50}
                                            />
                                        ) : (
                                            <>
                                                <button
                                                    type="button"
                                                    className={Buttons.btnText}
                                                    onClick={() =>
                                                        setShowOverlayDropdown(
                                                            false
                                                        )
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className={
                                                        Buttons.btnPrimary
                                                    }
                                                    onClick={handleAssignButton}
                                                >
                                                    Assign shift
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <span className="dragHereBox">
                                    Drag and drop open shift here
                                </span>
                            )}
                        </div>
                    )}
                    {useMobileDetect().isMobile() ? (
                        <MobilePage
                            openEditShiftModal={openEditModal}
                            allSchedules={allSchedules}
                            allEvents={allEvents}
                            updateTimeRange={updateTimeRange}
                            setCalendarType={setCalendarTypeHandler}
                            calendarType={calendarType}
                            openRequestsModal={openRequestsModal}
                        />
                    ) : (
                        <DesktopPage
                            openEditShiftModal={openEditModal}
                            openAddShiftModal={openAddModal}
                            allSchedules={allSchedules}
                            allEvents={allEvents}
                            workplacesData={workplacesData!}
                            updateTimeRange={updateTimeRange}
                            setView={setTimeViewHandler}
                            view={timeView}
                            setCalendarType={setCalendarTypeHandler}
                            calendarType={calendarType}
                            openRequestsModal={openRequestsModal}
                            openAddEventModal={openAddModal}
                            openEditEventModal={openEditModal}
                            reassignShiftToAnotherUser={
                                reassignShiftToAnotherUser
                            }
                            refetch={refetchSchedules}
                            updateOneShiftHandler={updateOneShiftHandler}
                        />
                    )}
                </div>
                <Navigation
                    filterReset={filterReset}
                    filters={filters}
                    setFilters={setFilters}
                    filterEvents={filterEvents}
                    calendarType={calendarType}
                    toPublish={toPublish}
                    isPublishable={isPublishable}
                    hasConflicts={hasConflicts}
                    schedules={schedules}
                    refetchSchedules={refetchSchedules}
                    openEmptyModalAddNewShift={openEmptyModalAddNewShift}
                    schedulesUnassigned={schedulesUnassigned}
                    openEditModal={openEditModal}
                    openAddModal={openAddModal}
                    openCustomerDatabaseModal={openCustomerDatabaseModal}
                />
            </section>

            {isAddShiftModalOpen ? (
                <Modal
                    title="Add new shift"
                    close={closeAddModalHandler}
                    render={() => (
                        <AddShiftModal
                            closeAddShiftModal={closeAddModalHandler}
                            addNewEvent={addNewEvent}
                            fromDate={fromDate}
                        />
                    )}
                />
            ) : null}
            {isEditShiftModalOpen ? (
                <Modal
                    title="Edit shift"
                    close={closeEditModalHandler}
                    render={() => (
                        <AddShiftModal
                            closeAddShiftModal={closeEditModalHandler}
                            editEvent={editEvent}
                            fromDate={fromDate}
                        />
                    )}
                />
            ) : null}
            {isAddEventModalOpen ? (
                <Modal
                    icon={event}
                    title="Create a new event"
                    close={closeAddModalHandler}
                    render={() => (
                        <AddEventModal
                            closeEventModal={closeAddModalHandler}
                            addNewEvent={addNewEvent}
                        />
                    )}
                />
            ) : null}
            {isEditEventModalOpen ? (
                <Modal
                    icon={event}
                    title="Edit event"
                    close={closeEditModalHandler}
                    render={() => (
                        <AddEventModal
                            closeEventModal={closeEditModalHandler}
                            editEvent={editEvent}
                        />
                    )}
                />
            ) : null}
            {isRequestAvailableModalOpen ? (
                <Modal
                    icon={wavingHand}
                    title="Availability request"
                    close={() => {
                        refetchSchedules();
                        closeRequestAvailableModalOpen();
                    }}
                    render={() => (
                        <AvailableShiftModal
                            closeModal={() => {
                                refetchSchedules();
                                closeRequestAvailableModalOpen();
                            }}
                            requestData={requestData}
                        />
                    )}
                />
            ) : null}
            {isTradeRequestModalOpen ? (
                <Modal
                    icon={trade}
                    title="Trade request"
                    close={() => {
                        refetchSchedules();
                        closeTradeRequestModal();
                    }}
                    render={() => (
                        <TradeRequestModal
                            closeModal={() => {
                                refetchSchedules();
                                closeTradeRequestModal();
                            }}
                            requestData={requestData}
                        />
                    )}
                />
            ) : null}
            {isTimeOffRequestModalOpen ? (
                <Modal
                    icon={timeOff}
                    title="Time off request"
                    close={() => {
                        refetchSchedules();
                        closeTimeOffRequestModal();
                    }}
                    render={() => (
                        <TimeOffRequestModal
                            closeModal={() => {
                                refetchSchedules();
                                closeTimeOffRequestModal();
                            }}
                            requestData={requestData}
                            role={auth.isManager}
                        />
                    )}
                />
            ) : null}
            {isShiftPickModalOpen ? (
                <Modal
                    icon={shiftPick}
                    title="Shift pick"
                    close={() => {
                        closeShiftPickModal();
                    }}
                    render={() => (
                        <ShiftPickModal
                            closeModal={() => closeShiftPickModal()}
                            requestData={requestData}
                        />
                    )}
                />
            ) : null}
            {isCoverRequestModalOpen ? (
                <Modal
                    icon={cover}
                    title="Cover request"
                    close={() => {
                        refetchSchedules();
                        closeCoverRequestModal();
                    }}
                    render={() => (
                        <CoverRequestModal
                            closeModal={() => {
                                refetchSchedules();
                                closeCoverRequestModal();
                            }}
                            requestData={requestData}
                            role={auth.isManager}
                        />
                    )}
                />
            ) : null}

            {isCustomerDatabaseModalOpen ? (
                <Modal
                    icon={customerDb}
                    title="Customer database"
                    close={() => {
                        closeCustomerDatabaseModal();
                    }}
                    bigModal
                    render={() => (
                        <CustomerDatabaseModal
                            closeModal={() => {
                                closeCustomerDatabaseModal();
                            }}
                        />
                    )}
                />
            ) : null}
        </>
    );
}
