import React from 'react';
import EmployeeScheduler from './pages/EmployeeScheduler';

export default function EmployeeRoot(): JSX.Element {
    return (
        <>
            <EmployeeScheduler />
        </>
    );
}
