import { apiService } from 'core/services/apiService';

export const RestartPassword = async (body: {
    email: string;
    callback_url: string;
}): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.post(`reset-password`, body)
    );

    return data;
};
