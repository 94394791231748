import { RestartPassword } from 'core/API/userAuth';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

import * as yup from 'yup';

export default function ResetPasswordModal({
    closeModal,
}: {
    closeModal: () => void;
}): JSX.Element {
    const [email, setEmail] = useState('');
    const { showToast } = useToastContext();

    const { mutate, isLoading } = useMutation(
        (body: { email: string; callback_url: string }) =>
            RestartPassword(body),
        {
            onSuccess: () => {
                closeModal();
                showToast('success', 'Check you email for your new password !');
            },
            onError: () => {
                showToast('error', 'Something went wrong !');
            },
        }
    );

    const schema = yup.object().shape({
        email: yup.string().email(),
    });

    return (
        <div className="FormResetPassword">
            <span>We will send you an email with your new password.</span>
            <input
                className="InputContent"
                autoFocus
                placeholder="example@domain.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            ></input>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <button
                    onClick={() => {
                        schema
                            .isValid({
                                email,
                            })
                            .then(function (valid) {
                                if (valid) {
                                    mutate({
                                        email: email,
                                        callback_url: '',
                                    });
                                } else {
                                    showToast('error', 'Email not valid !');
                                }
                            });
                    }}
                    disabled={isLoading || !email}
                >
                    Send
                </button>
            </div>
        </div>
    );
}
