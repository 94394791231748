import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginPage from 'module/auth/pages/LoginPage';
import { ROUTES } from 'module/navigation/enums/routes.enums';
import { PrivateRoute } from 'module/auth/PrivateRoutes';
import Layout from 'core/components/Layout/Layout';
import Toast from 'core/components/Toast/Toast';

// import SituationRoom from 'module/situationRoom/pages/SituationRoom';
// import GuestProtect from 'module/guestprotect/page/GuestProtect';
import GuestConnectContainer from 'module/guestconnect/page/GuestConnectContainer';
import GuestCheck from 'module/guestconnect/page/GuestCheck';

function App(): JSX.Element {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path={ROUTES.LOGIN} component={LoginPage} />
                    {/* <Route
                        exact
                        path={ROUTES.GUESTPROTECT}
                        component={GuestProtect}
                    /> */}
                    <Route
                        exact
                        path={ROUTES.GUESTCONNECTSTAFF}
                        component={GuestCheck}
                    />
                    <Route
                        exact
                        path={ROUTES.GUESTCONNECT}
                        component={GuestConnectContainer}
                    />

                    <PrivateRoute path="" component={Layout} />
                </Switch>
            </Router>
            <Toast />
        </>
    );
}

export default App;
