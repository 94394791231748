import { FC } from 'react';

// CSS modules
import Sections from '../sections.module.scss';

const displayValue = (value: number): string => {
    // return value.toFixed(2).replace(/[.,]00$/, '');
    return value.toFixed(2);
};

const MAD: FC<{ percentage: number }> = ({ percentage }) => {
    return (
        <article style={{ gridArea: 'mad' }} className={Sections.Mad}>
            <h4 style={{ paddingLeft: '16px', marginBottom: '20px' }}>
                MAD (Mabel Actual Deviation)
            </h4>

            <div style={{}} className={Sections.MadText}>
                <span
                    className={Sections.rowContentText}
                    style={{ fontSize: '70px' }}
                >
                    {percentage < 0
                        ? displayValue(0)
                        : displayValue(percentage)}
                    %
                </span>
            </div>

            <div></div>
        </article>
    );
};

export default MAD;
