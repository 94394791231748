import { FC, useRef } from 'react';
import { QueryObserverResult, RefetchOptions, useMutation } from 'react-query';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';

import { PostToDo } from 'core/API/todo';
import { closeSituationRoom, updatePriority } from 'core/API/situationRoom';

import { useAuth } from 'module/auth/context/AuthContext';
import { useToastContext } from 'core/components/Toast/context/ToastContext';

import { PrioritiesEnum } from '../../enums';

import Portal from 'core/components/Modal/Portal';

// CSS Modules
import Dialog from './dialog.module.scss';
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';

interface IDialog {
    close: () => void;
    data: any;
    refetchSituationRooms: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<any, unknown>>;
    refetchTopPriority: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<any, unknown>>;
}

const TakeActionDialog: FC<IDialog> = ({
    close,
    data,
    refetchSituationRooms,
    refetchTopPriority,
}) => {
    const auth = useAuth();
    const { showToast } = useToastContext();

    const commentRef = useRef(null);

    const { mutate: postToDo, isLoading: isLoadingPostTodo } = useMutation(
        PostToDo,
        {
            onSuccess: () => {
                close();
                closeAlert(data?.id);
                showToast(
                    'success',
                    'The item has been successfully posted as a ToDo item.'
                );
            },
        }
    );

    const { mutate: closeAlert, isLoading: isLoadingCloseAlert } = useMutation(
        closeSituationRoom,
        {
            onSuccess: () => {
                close();
                refetchSituationRooms();
                refetchTopPriority();
            },
        }
    );

    const { mutate: closeAction, isLoading: isLoadingCloseAction } =
        useMutation(closeSituationRoom, {
            onSuccess: () => {
                close();
                refetchSituationRooms();
                refetchTopPriority();
                showToast(
                    'success',
                    'The item has been successfully silenced.'
                );
            },
        });

    const { mutate: updatePriorityLevel, isLoading: isLoadingUpdatePriority } =
        useMutation(updatePriority, {
            onSuccess: () => {
                close();
                refetchSituationRooms();
                refetchTopPriority();
                showToast(
                    'success',
                    'Priority level has been successfully changed.'
                );
            },
        });

    const calculateDueDate = (priority: string) => {
        switch (priority) {
            case PrioritiesEnum.HIGH:
                return moment(new Date())
                    .add(30, 'minutes')
                    .format('YYYY-MM-DDTHH:mm:ssZ');
            case PrioritiesEnum.MEDIUM:
                return moment(new Date())
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ');
            case PrioritiesEnum.LOW:
                return moment(new Date())
                    .add(7, 'days')
                    .format('YYYY-MM-DDTHH:mm:ssZ');
            default:
                return null;
        }
    };

    const handlePostToDo = () => {
        const newToDo = {
            description: `${data?.source?.label} - ${data?.message}`,
            due_date: calculateDueDate(data?.priority?.name),
            log: null,
            priority: {
                id: data?.priority?.id,
            },
            user: null,
            status: {
                id: 1,
            },
            note: [
                {
                    date: data?.created_at,
                    user: auth?.user?.id,
                    status: 'New',
                    message: commentRef?.current?.value
                        ? `${data?.message}, ${commentRef?.current?.value}`
                        : data?.message,
                    user_full_name: `${auth?.user?.first_name} ${auth?.user?.last_name}`,
                },
            ],
            geolocation: null,
            workplace: {
                id: data?.workplace?.id,
            },
        };

        postToDo(newToDo);
    };

    const handleUpdatePriority = (actionType: string, priorityID: number) => {
        let body = {
            priority: {
                id: 0,
            },
        };

        switch (actionType) {
            case 'raise':
                body = {
                    priority: {
                        id: priorityID + 1,
                    },
                };

                updatePriorityLevel({ id: data?.id, body });

                break;

            case 'lower':
                body = {
                    priority: {
                        id: priorityID - 1,
                    },
                };

                updatePriorityLevel({ id: data?.id, body });

                break;
            default:
                break;
        }
    };

    return (
        <Portal>
            <div className={Dialog.dialogRoot}>
                <div className={Dialog.dialogWrapper}>
                    <div className={Dialog.dialogGroup}>
                        <p className={Dialog.dialogLabel}>Source</p>
                        <p className={Dialog.dialogText}>
                            {data?.source?.label}
                        </p>
                    </div>
                    <div className={Dialog.dialogGroup}>
                        <p className={Dialog.dialogLabel}>Time</p>
                        <p className={Dialog.dialogText}>
                            {moment(data?.created_at)
                                .utc()
                                .format('DD ddd MMM YYYY, hh:mm:ss A')}
                        </p>
                    </div>
                    <div className={Dialog.dialogGroup}>
                        <p className={Dialog.dialogLabel}>Message</p>
                        <p className={Dialog.dialogText}>{data?.message}</p>
                    </div>

                    {data?.file !== '' ? (
                        <div className={Dialog.dialogGroup}>
                            <p className={Dialog.dialogLabel}>Image</p>
                            <p className={Dialog.dialogText}>
                                <a
                                    href={
                                        process.env.REACT_APP_URL + data?.file
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={Buttons.btnImageLink}
                                >
                                    See full image
                                </a>
                            </p>
                        </div>
                    ) : null}
                    <div className={Dialog.dialogGroup}>
                        <label
                            htmlFor="textarea"
                            className={Dialog.dialogLabel}
                        >
                            Comment
                        </label>
                        <textarea
                            className={Forms.formTextarea}
                            name="comment"
                            id="textarea"
                            placeholder="Leave a comment"
                            maxLength={160}
                            ref={commentRef}
                        ></textarea>
                    </div>
                    <div className={Dialog.dialogActionButtons}>
                        {isLoadingPostTodo ||
                        isLoadingCloseAction ||
                        isLoadingUpdatePriority ? (
                            <ClipLoader
                                color="#841D80"
                                loading={true}
                                size={50}
                            />
                        ) : (
                            <>
                                <button
                                    type="button"
                                    className={Buttons.btnText}
                                    onClick={() => handlePostToDo()}
                                >
                                    Post ToDo
                                </button>
                                <button
                                    type="button"
                                    className={Buttons.btnText}
                                    onClick={() => closeAction(data?.id)}
                                    disabled={
                                        isLoadingCloseAction ||
                                        isLoadingCloseAlert
                                    }
                                >
                                    Silence
                                </button>
                                {data?.priority?.id < 3 && (
                                    <button
                                        type="button"
                                        className={Buttons.btnText}
                                        onClick={() =>
                                            handleUpdatePriority(
                                                'raise',
                                                data?.priority?.id
                                            )
                                        }
                                    >
                                        Raise priority
                                    </button>
                                )}
                                {data?.priority?.id > 1 && (
                                    <button
                                        type="button"
                                        className={Buttons.btnText}
                                        onClick={() =>
                                            handleUpdatePriority(
                                                'lower',
                                                data?.priority?.id
                                            )
                                        }
                                    >
                                        Lower priority
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={Dialog.dialogBackdrop} onClick={close}></div>
            </div>
        </Portal>
    );
};

export default TakeActionDialog;
