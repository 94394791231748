import 'module/employee/pages/EmployeeScheduler.style.scss';
import 'module/schedule/pages/SchedulePage.style.scss';
import { Dispatch, FC, SetStateAction } from 'react';
import { QueryObserverResult, RefetchOptions, useMutation } from 'react-query';

import { IPublishShifts, PutPublishShifts } from 'core/API/shifts'; // PROMIJENITI U INTERFACE FOLDER

import { MbscCalendarEvent } from '@mobiscroll/react';
import {
    CalendarType,
    ISchedule,
    MenuView,
} from '../../interfaces/ScheduleInterface';

import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useMobileDetect } from 'core/hooks/useMobileDetect';

import publishSchedule from 'assets/icons/PublishSchedule.svg';
// import copyWeek from 'assets/icons/CopyWeek.svg';
import cover from 'assets/icons/Cover.svg';
import event from 'assets/icons/Event.svg';
import shiftPick from 'assets/icons/ShiftPick.svg';
import timeOff from 'assets/icons/TimeOff.svg';
import trade from 'assets/icons/Trade.svg';
import wavingHand from 'assets/icons/WavingHand.svg';

import Loading from 'core/components/Loading/Loading';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';
import NavButton from '../NavButton/NavButton';
import NavShift from '../NavShift/NavShift';

// CSS modules
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { formatTimeWithtimezone } from 'shared/utils/dates/formatTimeWithTimezone';
import Buttons from 'styles/buttons.module.scss';
import Icons from 'styles/icons.module.scss';

type Filter = {
    id: number;
    name: string;
    active: boolean;
};

function Shift(props: any) {
    console.log('shift props', props);
    const DateOfShift = new Date(props.event.start_at);

    const hours =
        Math.abs(
            new Date(props.event.start_at).getTime() -
                new Date(props.event.end_at).getTime()
        ) / 3600000;

    return (
        <div className="external-drop-task" onClick={props.onClick}>
            <h6>
                {`${DateOfShift.getDate()}/${
                    DateOfShift.getMonth() + 1
                }/${DateOfShift.getFullYear()} ${hours}h`}
            </h6>
            <p>{props.event?.roles[0]?.name}</p>
        </div>
    );
}

interface INavigation {
    filterReset?: (type: boolean) => void;
    filters?: Filter[];
    setFilters?: Dispatch<SetStateAction<Filter[]>>;
    filterEvents?: () => void;
    calendarType?: CalendarType;
    toPublish?: number;
    isPublishable?: boolean;
    hasConflicts?: boolean;
    schedules?: ISchedule;
    refetchSchedules?: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<ISchedule, any>>;
    openEmptyModalAddNewShift?: () => void;
    schedulesUnassigned?: any;
    openEditModal?: (event: any) => void;
    openAddModal?: (event: any) => void;
    menuView?: MenuView;
    setMenuView?: React.Dispatch<SetStateAction<MenuView>>;
    handleOpenShiftClick?: (ID: number) => void;
    tradeMyShift?: MbscCalendarEvent | null;
    tradeOtherShift?: MbscCalendarEvent | null;
    isLoadingOtherShift?: boolean;
    isLoadingMyShift?: boolean;
    startTradeShiftHandler?: () => void;
    setTradeMyShift?: React.Dispatch<SetStateAction<MbscCalendarEvent | null>>;
    setTradeOtherShift?: React.Dispatch<
        SetStateAction<MbscCalendarEvent | null>
    >;
    tradeMenuClickHandler?: () => void;
    openAvailabilityModal?: () => void;
    openTimeOffModal?: () => void;
    openCoverModal?: () => void;
    openCustomerDatabaseModal?: () => void;
}

const Navigation: FC<INavigation> = ({
    filterReset,
    filters,
    setFilters,
    filterEvents,
    calendarType,
    toPublish,
    isPublishable,
    hasConflicts,
    schedules,
    refetchSchedules,
    openEmptyModalAddNewShift,
    schedulesUnassigned,
    openEditModal,
    openAddModal,
    menuView,
    setMenuView,
    handleOpenShiftClick,
    tradeMyShift,
    tradeOtherShift,
    isLoadingMyShift,
    isLoadingOtherShift,
    startTradeShiftHandler,
    setTradeMyShift,
    setTradeOtherShift,
    tradeMenuClickHandler,
    openAvailabilityModal,
    openTimeOffModal,
    openCoverModal,
    openCustomerDatabaseModal,
}) => {
    const { showToast } = useToastContext();

    const { timezone } = useWorkplacesContext();

    const { mutate: PublishShifts, isLoading: isLoadingPublishShifts } =
        useMutation(PutPublishShifts);

    const filterChooseHandler = (id: number) => {
        const newArr = filters.map((value) => {
            if (value.id === id) {
                value.active = !value.active;

                return {
                    ...value,
                };
            }

            return value;
        });

        setFilters(newArr);
        filterEvents();
    };

    const publishScheduleHandler = () => {
        if (isPublishable) {
            if (hasConflicts) {
                showToast('error', 'Please resolve conflicts!');
            } else {
                const shiftsToPublish: IPublishShifts = { shifts: [] };

                schedules?.items.forEach((shift) => {
                    if (!shift.published) {
                        shiftsToPublish.shifts.push({ id: shift.id });
                    }
                });

                PublishShifts(shiftsToPublish, {
                    onSuccess: () => {
                        refetchSchedules();
                        showToast('success', 'Schedule is published');
                    },
                });
            }
        }
    };

    return (
        <>
            {calendarType === 'schedule' ? (
                <aside className="Navigation">
                    <h5 className="NavigationOptionsTitle ">Manager options</h5>
                    <NavButton
                        icon={publishSchedule}
                        label="Publish schedule"
                        isDisabled={
                            !isPublishable ||
                            isLoadingPublishShifts ||
                            !toPublish
                        }
                        isOutlined
                        onClick={publishScheduleHandler}
                    />
                    <NavButton
                        label={`Publish changes (${toPublish})`}
                        isDisabled={
                            !isPublishable ||
                            !toPublish ||
                            isLoadingPublishShifts
                        }
                    />
                    {/* this feature is not out yet, comment for now */}
                    {/* <NavButton icon={copyWeek} label="Copy to the next week" /> */}
                    <NavButton
                        icon={shiftPick}
                        label="Create a shift"
                        onClick={openEmptyModalAddNewShift}
                    />
                    {useMobileDetect().isMobile() ? null : (
                        <div>
                            <div className="mbsc-col-sm-3">
                                <div className="mbsc-form-group-title">
                                    <h5 className="NavigationOptionsTitle">
                                        Open shifts
                                    </h5>
                                </div>
                                {schedulesUnassigned?.map(
                                    (event: any, index: number) => {
                                        return (
                                            <NavShift
                                                draggable
                                                key={index}
                                                event={event}
                                                timezone={timezone}
                                                onClick={() => {
                                                    const customEvent = {
                                                        shift_id: event.id,
                                                        start: event.start_at,
                                                        end: event.end_at,
                                                    };
                                                    openEditModal(customEvent);
                                                }}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    )}
                </aside>
            ) : menuView === 'regular' ? (
                <aside className="Navigation">
                    {useMobileDetect().isMobile() ? null : (
                        <div className="mbsc-form-group-title">
                            Employee options
                        </div>
                    )}
                    <div
                        className="NavigationBox"
                        onClick={() => {
                            openAvailabilityModal();
                        }}
                    >
                        <SvgIcon
                            spriteUrl={wavingHand}
                            className={Icons.navIcon}
                        />
                        <p>Request availability</p>
                    </div>

                    <div
                        className="NavigationBox"
                        onClick={() => {
                            openTimeOffModal();
                        }}
                    >
                        <SvgIcon
                            spriteUrl={timeOff}
                            className={Icons.navIcon}
                        />
                        <p>Request time off</p>
                    </div>
                    <div
                        className="NavigationBox"
                        onClick={() => {
                            openCoverModal();
                        }}
                    >
                        <SvgIcon spriteUrl={cover} className={Icons.navIcon} />
                        <p>Request cover</p>
                    </div>
                    <div
                        className="NavigationBox"
                        onClick={() => {
                            tradeMenuClickHandler();
                        }}
                    >
                        <SvgIcon spriteUrl={trade} className={Icons.navIcon} />
                        <p>Request trade</p>
                    </div>

                    {useMobileDetect().isMobile() ? null : (
                        <div>
                            <div className="mbsc-col-sm-3">
                                <div className="mbsc-form-group-title">
                                    Pick an open shift
                                </div>

                                {schedulesUnassigned?.map(
                                    (event: any, index: number) => {
                                        return (
                                            <Shift
                                                key={index}
                                                event={event}
                                                onClick={() =>
                                                    handleOpenShiftClick(
                                                        event.id
                                                    )
                                                }
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    )}
                </aside>
            ) : calendarType === 'request' ? (
                <aside className="Navigation NavigationRequests">
                    <h5>Show requests</h5>
                    <div className="NavigationRow">
                        <NavButton
                            label="Choose all"
                            onClick={() => filterReset(true)}
                        />
                        <NavButton
                            label="Remove all"
                            onClick={() => filterReset(false)}
                        />
                    </div>
                    <div className="NavigationFilterButtons">
                        {filters.map((value, index) => {
                            switch (value.id) {
                                case 3:
                                    return (
                                        <NavButton
                                            key={index}
                                            icon={shiftPick}
                                            label="Shift picks"
                                            onClick={() =>
                                                filterChooseHandler(value.id)
                                            }
                                            isInactive={!value.active}
                                        />
                                    );

                                case 4:
                                    return (
                                        <NavButton
                                            key={index}
                                            icon={wavingHand}
                                            label="Availability requests"
                                            onClick={() =>
                                                filterChooseHandler(value.id)
                                            }
                                            isInactive={!value.active}
                                        />
                                    );

                                case 2:
                                    return (
                                        <NavButton
                                            key={index}
                                            icon={timeOff}
                                            label="Time off requests"
                                            onClick={() =>
                                                filterChooseHandler(value.id)
                                            }
                                            isInactive={!value.active}
                                        />
                                    );

                                case 5:
                                    return (
                                        <NavButton
                                            key={index}
                                            icon={cover}
                                            label="Cover requests"
                                            onClick={() =>
                                                filterChooseHandler(value.id)
                                            }
                                            isInactive={!value.active}
                                        />
                                    );

                                case 6:
                                    return (
                                        <NavButton
                                            key={index}
                                            icon={trade}
                                            label="Trade requests"
                                            onClick={() =>
                                                filterChooseHandler(value.id)
                                            }
                                            isInactive={!value.active}
                                        />
                                    );
                                default:
                                    break;
                            }
                        })}
                    </div>
                </aside>
            ) : menuView === 'trade' ? (
                <aside className="NavigationTrade">
                    <div
                        className="mbsc-form-group-title"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <SvgIcon spriteUrl={trade} className={''} />
                        <p>Request trade</p>
                    </div>
                    <div className="TradeBox">
                        <h5>My shift</h5>
                        <p>
                            {tradeMyShift === null
                                ? `Choose one of your shifts on calendar`
                                : `${formatTimeWithtimezone(
                                      tradeMyShift.start,
                                      timezone,
                                      'ddd, D MM YYYY, HH:mm'
                                  )} - ${formatTimeWithtimezone(
                                      tradeMyShift.end,
                                      timezone,
                                      'HH:mm'
                                  )}`}
                        </p>
                    </div>
                    <div className="TradeBox">
                        <h5>Someone else’s shift</h5>
                        <p>
                            {tradeOtherShift === null
                                ? `Choose shift in calendar`
                                : `
                                
                                ${formatTimeWithtimezone(
                                    tradeOtherShift.start,
                                    timezone,
                                    'ddd, D MM YYYY, HH:mm'
                                )} - ${formatTimeWithtimezone(
                                      tradeOtherShift.end,
                                      timezone,
                                      'HH:mm'
                                  )}`}
                        </p>
                    </div>
                    <footer className="tradeFooter">
                        {isLoadingMyShift || isLoadingOtherShift ? (
                            <Loading />
                        ) : (
                            <>
                                <button
                                    className={Buttons.btnPrimary}
                                    style={{ width: '204px' }}
                                    onClick={startTradeShiftHandler}
                                >
                                    Save trade request
                                </button>
                                <button
                                    className={Buttons.btnText}
                                    style={{
                                        width: useMobileDetect().isMobile()
                                            ? '130px'
                                            : '204px',
                                    }}
                                    onClick={() => {
                                        refetchSchedules();
                                        setTradeMyShift(null);
                                        setTradeOtherShift(null);

                                        setMenuView('regular');
                                    }}
                                >
                                    Cancel
                                </button>
                            </>
                        )}
                    </footer>
                </aside>
            ) : (
                <aside className="Navigation">
                    <NavButton
                        icon={event}
                        label="Create a new event"
                        onClick={openAddModal}
                    />
                    <NavButton
                        icon={event}
                        label="Customer database"
                        onClick={openCustomerDatabaseModal}
                    />
                </aside>
            )}
        </>
    );
};

export default Navigation;
