import { apiService } from 'core/services/apiService';

export interface IRoles {
    total_results: number;
    total_pages: number;
    current_page: number;
    limit: number;
    offset: number;
    order_by: string;
    direction: string;
    items: Item[];
}

export interface Item {
    id: number;
    identifier: string;
    name: string;
    permission: Permission[];
    active: boolean;
    deleted: boolean;
    background_color: string;
    text_color: string;
}

export interface Permission {
    id: number;
    identifier: string;
    name: string;
}

const GetRolesList = async (): Promise<IRoles> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(`roles`)
    );

    return data;
};

export { GetRolesList };
