import { ShiftCategoryInterface } from 'core/interfaces/shiftCategoryInterface';
import { ShiftReasonInterface } from 'core/interfaces/shiftReasonInterface';
import { apiService } from 'core/services/apiService';
import {
    IShiftGetOne,
    IShiftsPost,
} from 'module/schedule/interfaces/ShiftsInterface';

export interface IObj {
    shift: number;
    role: number;
}

const PostShifts = async (body: IShiftsPost): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.post(`shifts`, body)
    );

    return data;
};

const PostShiftsAddRole = async (obj: IObj): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.post(`shifts/${obj.shift}/roles/${obj.role}`)
    );

    return data;
};

const DeleteShiftsAddRole = async (obj: IObj): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.delete(`shifts/${obj.shift}/roles/${obj.role}`)
    );

    return data;
};

const DeleteShift = async (shiftID: number): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.delete(`shifts/${shiftID}`)
    );

    return data;
};

const GetOneShift = async (shiftID: number): Promise<IShiftGetOne> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(`shifts/${shiftID}`)
    );

    return data;
};

export interface IPutOneShift {
    body: IShiftsPost;
    id: number | null;
}

const PutOneShift = async (value: IPutOneShift): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.put(`shifts/${value.id}`, {
            ...value.body,
        })
    );

    return data;
};

export interface IPublishShifts {
    shifts: Shift[];
}

export interface Shift {
    id: number;
}

const PutPublishShifts = async (body: IPublishShifts): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.put(`shifts/publish`, body)
    );

    return data;
};

const GetShiftCategories = async (): Promise<ShiftCategoryInterface> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(`shift-category`)
    );

    return data;
};

const GetShiftReasons = async (): Promise<ShiftReasonInterface> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(`shift-reasons`)
    );

    return data;
};

export {
    PostShifts,
    PostShiftsAddRole,
    DeleteShift,
    GetOneShift,
    PutOneShift,
    PutPublishShifts,
    DeleteShiftsAddRole,
    GetShiftCategories,
    GetShiftReasons,
};
