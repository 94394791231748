import { managerNavigationItems } from 'module/navigation/constants/manager-navigation-items';
import { Route } from 'react-router-dom';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const managerContentRoutes = managerNavigationItems.filter(
    ({ contentComponent }) => contentComponent
);

const isExact = (path: string) => {
    switch (path) {
        // case ROUTES.USER:
        //     return false;
        default:
            return true;
    }
};

export default function Manager(): JSX.Element {
    return (
        <>
            <DndProvider backend={HTML5Backend}>
                {managerContentRoutes.map(
                    ({ path, contentComponent }, index) => (
                        <Route
                            key={index}
                            exact={isExact(path)}
                            path={path}
                            component={contentComponent}
                        ></Route>
                    )
                )}
            </DndProvider>
        </>
    );
}
