import moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const fmt = 'MM/DD/YYYY HH:mm:ss';

export default function getTimeWithTimezone(
    firstday: Date,
    lastday: Date,
    timezone: string
): string[] {
    const newFirstDay = moment(firstday).format(fmt);
    const newStart_at = moment
        .tz(newFirstDay, fmt, timezone)
        .utc()
        .format(DATE_FORMAT);

    const newLastDay = moment(lastday).format(fmt);

    const newEnd_at = moment
        .tz(newLastDay, fmt, timezone)
        .utc()
        .format(DATE_FORMAT);

    return [newStart_at, newEnd_at];
}

/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/
export const formatTimeWithtimezone = (
    time: any,
    timezone: string,
    format: string
): string => {
    const start = moment.tz(time.split('+')[0], 'UTC'); // original timezone

    return start.tz(timezone).format(format);
};
