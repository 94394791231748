import { ReactComponent as QRValid } from 'assets/icons/QRValid.svg';
import { ReactComponent as QRInvalid } from 'assets/icons/QRInvalid.svg';
import logo from 'assets/images/logo.png';

import style from './GuestConnect.module.scss';
import { useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { CheckGuestConnect, PutGuestConnect } from 'core/API/workplaces';
import Loading from 'core/components/Loading/Loading';
import { useToastContext } from 'core/components/Toast/context/ToastContext';

interface IurlParams {
    id: string;
}

export default function GuestCheck(): JSX.Element {
    const { showToast } = useToastContext();

    const { id }: IurlParams = useParams();

    const { isLoading, data, isError, refetch } = useQuery(
        ['GuestConnectCheck', id],
        () => CheckGuestConnect(id),
        {
            retry: 1,
        }
    );

    const { mutate, isSuccess } = useMutation(PutGuestConnect);

    const submitCode = () => {
        mutate(id, {
            onSuccess: () => {
                refetch();
                showToast('success', 'Reward successfully used !');
            },
        });
    };

    console.log(isSuccess);
    return (
        <div className={style.Container}>
            <section className={style.ContainerBox}>
                <img src={logo} alt="Logo" className={style.ContainerBoxImg} />
                {isLoading ? (
                    <Loading />
                ) : isError ? (
                    <>
                        <div className={style.QRBox}>
                            <QRInvalid />
                            <p className={style.isError}>
                                QR code is not valid.
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style.QRBox}>
                            <QRValid />
                            <p>QR code is valid.</p>
                            <h3>{data.workplace}</h3>
                            <h3>{data.domain}</h3>
                            <h3>{data.message}</h3>
                        </div>
                        {isSuccess || data.reward_used ? null : (
                            <button
                                onClick={submitCode}
                                className={style.GCButton}
                            >
                                Claim reward
                            </button>
                        )}
                    </>
                )}
            </section>
        </div>
    );
}
