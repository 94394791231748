import {
    CalendarNav,
    CalendarNext,
    CalendarPrev,
    CalendarToday,
    Eventcalendar,
    MbscCalendarEvent,
    MbscCalendarEventData,
    MbscEventcalendarView,
    MbscResource,
    momentTimezone,
    setOptions,
} from '@mobiscroll/react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import cover from 'assets/icons/Cover.svg';
import shiftPick from 'assets/icons/ShiftPick.svg';
import statusPublished from 'assets/icons/StatusPublished.svg';
import statusUnpublished from 'assets/icons/StatusUnpublished.svg';
import timeOff from 'assets/icons/TimeOff.svg';
import trade from 'assets/icons/Trade.svg';
import wavingHand from 'assets/icons/WavingHand.svg';
import { fetchAllEventTypes } from 'core/API/events';
import { GetUsersList } from 'core/API/users';
// CSS modules
import Loading from 'core/components/Loading/Loading';
import { useAuth } from 'module/auth/context/AuthContext';
import { TimeView } from 'module/schedule/interfaces/ScheduleInterface';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { IWorkplaces } from 'module/workplaces/interface/WorkplacesInterface';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';
import Buttons from 'styles/buttons.module.scss';
import Icons from 'styles/icons.module.scss';

import './DesktopPage.style.scss';

// setup Mobiscroll Moment plugin
momentTimezone.moment = momentTZ;

interface IDesktopPage {
    openEditShiftModal: (args: any) => void;
    openAddShiftModal: (args: any) => void;
    allSchedules: MbscCalendarEvent[];
    allEvents: MbscCalendarEvent[];
    workplacesData: IWorkplaces;
    updateTimeRange: (firstday: Date, lastday: Date) => void;
    setView: (time: TimeView) => void;
    view: string;
    setCalendarType: (args: 'schedule' | 'request' | 'event') => void;
    calendarType: string;
    openRequestsModal: (args: any) => void;
    openAddEventModal: (args: any) => void;
    openEditEventModal: (args: any) => void;
    reassignShiftToAnotherUser: (newShift: MbscCalendarEvent) => void;
    refetch: any;
    updateOneShiftHandler: (selectedEvent: MbscCalendarEvent) => void;
}

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

const DesktopPage: FC<IDesktopPage> = ({
    openEditShiftModal,
    openAddShiftModal,
    allSchedules,
    allEvents,
    workplacesData,
    updateTimeRange,
    setView,
    view,
    setCalendarType,
    calendarType,
    openRequestsModal,
    openAddEventModal,
    openEditEventModal,
    reassignShiftToAnotherUser,
    refetch,
    updateOneShiftHandler,
}) => {
    const { workkplaceID, timezone } = useWorkplacesContext();
    const auth = useAuth();

    const [calView, setCalView] = useState<MbscEventcalendarView>({
        timeline: {
            type: 'day',
            timeCellStep: 60,
            timeLabelStep: 60,
        },
    });

    const [invalidDates, setInvalidDates] = useState<any>();

    const {
        data: usersData,
        isLoading: isLoadingUsersWorkplace,
        refetch: refetchUsers,
    } = useQuery(['users', workkplaceID], () => GetUsersList(workkplaceID), {
        enabled: calendarType === 'schedule' || calendarType === 'request',
    });

    const { data: eventTypes } = useQuery(
        ['eventTypes'],
        () => fetchAllEventTypes(),
        {
            enabled: calendarType === 'event',
        }
    );

    useEffect(() => {
        if (allSchedules.length) {
            const tempArrayOfInvalidDates = [];
            allSchedules.forEach((shift) => {
                if (shift?.shift_type?.id === 2 && shift?.scheduled) {
                    console.log(shift);
                    tempArrayOfInvalidDates.push({
                        start: moment(shift.start).toDate(),
                        end: moment(shift.end).toDate(),
                        title: 'Time off',
                        resource: shift.resource,
                    });
                }
            });
            setInvalidDates(tempArrayOfInvalidDates);
            refetchUsers();
        }
    }, [allSchedules]);

    const myResources = useMemo<MbscResource[]>(() => {
        const usersListResource: MbscResource[] = [];
        const firstUser: MbscResource[] = [];
        usersData?.forEach(
            (value) => {
                if (value.user.id === auth.user.id) {
                    firstUser.push({
                        id: value.user.id,
                        name: `${value.user.first_name} ${value.user.last_name}`,
                        role: value.role,
                        active_week_hours: value.active_week_hours,
                    });
                } else {
                    usersListResource.push({
                        id: value.user.id,
                        name: `${value.user.first_name} ${value.user.last_name}`,
                        role: value.role,
                        active_week_hours: value.active_week_hours,
                    });
                }
            },
            [usersData]
        );

        // sort users by their shifts
        usersListResource.sort((a, b) => {
            let userAshift = null;
            let userBshift = null;

            allSchedules.forEach((shift) => {
                //console.log('display shitf', shift);
                if (shift.resource === a.id) {
                    userAshift = shift;
                } else if (shift.resource === b.id) {
                    userBshift = shift;
                }
            });

            if (userAshift !== null && userBshift !== null) {
                const firstShiftStart = new Date(userAshift.start).getTime();
                const secondShiftStart = new Date(userBshift.start).getTime();

                const titleA = userAshift.title.split(' ');
                const titleB = userBshift.title.split(' ');

                if (userAshift?.role && userBshift?.role) {
                    return (
                        userAshift?.role.localeCompare(userBshift?.role) ||
                        firstShiftStart - secondShiftStart ||
                        titleB[titleB.length - 1].localeCompare(
                            titleA[titleA.length - 1]
                        )
                    );
                }

                return (
                    firstShiftStart - secondShiftStart ||
                    titleB[titleB.length - 1].localeCompare(
                        titleA[titleA.length - 1]
                    )
                );
            }

            // if (userAshift === null) {
            //     return 0;
            // }

            // if last user does not have shift push them to the end
            if (userBshift === null) {
                return -1;
            }

            return 0;
        });

        // sort by roles

        return [...firstUser, ...usersListResource]!;
    }, [usersData, allSchedules]);

    const eventResources = useMemo<MbscResource[]>(() => {
        const eventsListResource = eventTypes?.items?.map((eventType: any) => {
            return {
                id: eventType.id,
                name: eventType.type,
            };
        });

        return eventsListResource!;
    }, [eventTypes]);

    useEffect(() => {
        changeView(view);
    }, []);

    const changeView = (event: any) => {
        let calView: MbscEventcalendarView;

        switch (event) {
            case 'day': {
                // const dateToday = new Date();
                // const dateTomorrow = new Date(dateToday);
                // dateTomorrow.setDate(dateTomorrow.getDate() + 1);

                // updateTimeRange(dateToday, dateToday);
                calView = {
                    timeline: {
                        type: 'day',
                        timeCellStep: 60,
                        timeLabelStep: 60,
                    },
                };

                break;
            }
            case 'workweek':
                calView = {
                    timeline: {
                        type: 'week',
                        eventList: true,
                        startDay: 1,
                        endDay: 5,
                    },
                };
                break;
            case 'week':
            default: {
                // const curr = new Date();
                // const first = curr.getDate() - curr.getDay();
                // const last = first + 6;

                // const firstday = new Date(curr.setDate(first));
                // const lastday = new Date(curr.setDate(last));

                // updateTimeRange(firstday, lastday);

                calView = {
                    timeline: {
                        type: 'week',
                        eventList: true,
                        startDay: 1,
                        endDay: 0,
                        timeCellStep: 1440,
                        timeLabelStep: 1440,
                    },
                };
                break;
            }
        }

        console.log(calView);

        setView(event);
        setCalView(calView);
    };

    const renderMyHeader = () => {
        return (
            <>
                <div className="customHeader">
                    <div className="customHeaderTop">
                        <CalendarNav className="md-work-week-nav" />
                        <div className="md-work-week-picker">
                            <div className={Buttons.btnToggleThree}>
                                <button
                                    type="button"
                                    className={`${
                                        Buttons.btnToggleDefaultThree
                                    } ${
                                        calendarType === 'schedule'
                                            ? Buttons.btnToggleActiveThree
                                            : null
                                    }`}
                                    onClick={() => setCalendarType('schedule')}
                                >
                                    Schedule
                                </button>
                                <button
                                    type="button"
                                    className={`${
                                        Buttons.btnToggleDefaultThree
                                    } ${
                                        calendarType === 'request'
                                            ? Buttons.btnToggleActiveThree
                                            : null
                                    }`}
                                    onClick={() => setCalendarType('request')}
                                >
                                    Requests
                                </button>
                                <button
                                    type="button"
                                    className={`${
                                        Buttons.btnToggleDefaultThree
                                    } ${
                                        calendarType === 'event'
                                            ? Buttons.btnToggleActiveThree
                                            : null
                                    }`}
                                    onClick={() => setCalendarType('event')}
                                >
                                    Events
                                </button>
                            </div>
                            <div className={Buttons.btnToggle}>
                                <button
                                    type="button"
                                    className={`${Buttons.btnToggleDefault} ${
                                        view === 'day'
                                            ? Buttons.btnToggleActive
                                            : null
                                    }`}
                                    onClick={() => changeView('day')}
                                >
                                    Day
                                </button>
                                <button
                                    type="button"
                                    className={`${Buttons.btnToggleDefault} ${
                                        view === 'week'
                                            ? Buttons.btnToggleActive
                                            : null
                                    }`}
                                    onClick={() => changeView('week')}
                                >
                                    Week
                                </button>
                            </div>
                        </div>
                        <div>
                            <CalendarPrev className="md-work-week-prev" />
                            <CalendarToday className="md-work-week-today" />
                            <CalendarNext className="md-work-week-next" />
                        </div>
                    </div>
                    <div className="customHeaderBottom">
                        <span className="WorkplaceTitle">
                            Workplace:
                            <span className="WorkplaceName">
                                {workplacesData?.items?.map(
                                    ({ workplace }) =>
                                        workkplaceID === workplace.id &&
                                        workplace.name
                                )}
                            </span>
                        </span>
                    </div>
                </div>
            </>
        );
    };

    const renderMyResource = (resource: MbscResource) =>
        calendarType === 'event' ? (
            <div className={`md-work-week-cont`}>
                <div className="md-work-week-name">{resource.name}</div>
                <div className="md-work-week-title">
                    {resource?.role
                        ?.map(({ name }: any) => `${name}`)
                        .join(', ')}
                </div>
            </div>
        ) : (
            <div className={`md-work-week-cont`}>
                <div className="md-work-week-name">
                    {resource.name} ({resource?.active_week_hours} h)
                </div>
                <div className="md-work-week-title">
                    {resource?.role
                        ?.map(({ name }: any) => `${name}`)
                        .join(', ')}
                </div>
                {/* <img
                    className="md-work-week-avatar"
                    src={resource.img}
                    alt="Avatar"
                /> */}
            </div>
        );

    const myScheduleEvent = useCallback(
        (data: MbscCalendarEventData) => {
            const original = data.original!;

            //console.log('original', original);
            if (original?.shift_type?.id === 2 && original?.scheduled) return;
            return (
                <div
                    className={`md-timeline-template-event ${
                        original.overlap ? 'hasStripeBackground' : ''
                    }
                    ${calendarType === 'request' ? 'requestsEventCustom' : ''}
                    ${
                        !original.role && calendarType === 'event'
                            ? 'scheduleEvent'
                            : ''
                    }
                    ${
                        original.role === undefined &&
                        calendarType === 'request'
                            ? 'timeoffColor'
                            : ''
                    }
                    `}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: original?.background_color,
                        color: original?.text_color,
                        borderColor: original?.background_color,
                    }}
                >
                    <div className="md-custom-event-wrapper">
                        <div className="md-custom-event-details">
                            <div className="md-custom-event-title">
                                {original.role !== undefined ||
                                calendarType !== 'request'
                                    ? `${data.start} - ${data.end}`
                                    : 'Time off'}
                            </div>
                        </div>
                        {calendarType === 'event' ? (
                            <div className="md-timeline-template-role">
                                {original?.name}, {original?.location}
                            </div>
                        ) : (
                            <div className="md-timeline-template-role">
                                {original?.role ?? 'No Role'}
                            </div>
                        )}
                    </div>
                    <div className="md-timeline-template-icon">
                        {!original.scheduled ? (
                            original?.shift_type?.id === 4 ? (
                                <SvgIcon
                                    spriteUrl={wavingHand}
                                    className={Icons.shiftIcon}
                                />
                            ) : original?.shift_type?.id === 2 ? (
                                <SvgIcon
                                    spriteUrl={timeOff}
                                    className={Icons.shiftIcon}
                                />
                            ) : original?.shift_type?.id === 6 ? (
                                <SvgIcon
                                    spriteUrl={trade}
                                    className={Icons.shiftIcon}
                                />
                            ) : original?.shift_type?.id === 3 ? (
                                <SvgIcon
                                    spriteUrl={shiftPick}
                                    className={Icons.shiftIcon}
                                />
                            ) : original?.shift_type?.id === 5 ? (
                                <SvgIcon
                                    spriteUrl={cover}
                                    className={Icons.shiftIcon}
                                />
                            ) : null
                        ) : original.overlap ? (
                            <SvgIcon
                                spriteUrl={statusUnpublished}
                                className={Icons.shiftIcon}
                            />
                        ) : original.published ? (
                            <SvgIcon
                                spriteUrl={statusPublished}
                                className={Icons.shiftIcon}
                            />
                        ) : null}
                    </div>
                </div>
            );
        },
        [calendarType]
    );

    const compareEvents = (selectedEvent: MbscCalendarEvent) => {
        allSchedules.forEach((schedule) => {
            if (schedule.id === selectedEvent.id) {
                if (selectedEvent.resource !== schedule.resource) {
                    // check for roles of the other user and update shift

                    reassignShiftToAnotherUser(selectedEvent);
                } else {
                    // open modal for update shift
                    // openEditShiftModal(selectedEvent);
                    // call function to update shift
                    updateOneShiftHandler(selectedEvent);
                }
            }
        });
    };

    const disableDeleteKey = () => {
        refetch();
    };

    // const orderMyEvents = useCallback((event) => {
    //     console.log(event);
    //     return event.accepted ? 1 : -1;
    // }, []);

    return isLoadingUsersWorkplace ? (
        <Loading />
    ) : (
        <Eventcalendar
            invalid={invalidDates}
            invalidateEvent="strict"
            theme="ios"
            /* width={'82vw'} */
            dataTimezone={timezone}
            displayTimezone={timezone}
            timezonePlugin={momentTimezone}
            themeVariant="light"
            clickToCreate={true}
            // dragToCreate={calendarType !== 'request'}
            dragToCreate={false}
            dragToMove={true}
            dragToResize={view !== 'week'} //disable resize shift only on week view
            view={calView}
            data={calendarType === 'event' ? allEvents : allSchedules}
            renderScheduleEvent={myScheduleEvent}
            // invalid={myInvalids}
            resources={calendarType === 'event' ? eventResources : myResources}
            renderHeader={renderMyHeader}
            renderResource={renderMyResource}
            dragTimeStep={30}
            cssClass="md-switching-timeline-view-cont md-timeline-height"
            timeFormat="HH:mm"
            externalDrop={true}
            // eventOrder={orderMyEvents}
            onCellClick={(event) => {
                switch (calendarType) {
                    case 'event':
                        openAddEventModal(event);
                        break;
                    default:
                        openAddShiftModal(event);
                        break;
                }
            }}
            onEventDoubleClick={({ event }) => {
                /* Logic for event double click */
                if (event.role || !event.scheduled) {
                    // if no role its timeoff disable popup on timeoff
                    switch (calendarType) {
                        case 'request':
                            openRequestsModal(event);
                            break;
                        case 'event':
                            openEditEventModal({
                                ...event,
                                end: new Date(
                                    event.end
                                        ? event.end.toString().split('+')[0]
                                        : ''
                                ).toString(),
                                start: new Date(
                                    event.start
                                        ? event.start.toString().split('+')[0]
                                        : ''
                                ).toString(),
                            });
                            break;
                        default:
                            openEditShiftModal(event);
                            break;
                    }
                }
            }}
            onEventUpdate={({ event }) => {
                /* Logic for event update */
                switch (calendarType) {
                    case 'request':
                        openRequestsModal(event);
                        break;
                    case 'event':
                        openEditEventModal({
                            ...event,
                            fromUtc: moment(event?.start).format('HH:mm'),
                            toUtc: moment(event?.end).format('HH:mm'),
                        });
                        break;
                    default:
                        compareEvents(event);
                        break;
                }
            }}
            onPageChange={(event) => {
                /* Your custom event handler goes here */
                // setFromDate(formatDate(event.firstDay));
                // setToDate(formatDate(event.lastDay));
                updateTimeRange(event.firstDay, event.lastDay);
            }}
            onEventCreate={(event) => {
                if (event.action === 'drag') return;

                if (event.action === 'externalDrop') {
                    openEditShiftModal(event.event);
                } else {
                    openAddShiftModal(event);
                }
            }}
            onEventClick={() => {
                disableDeleteKey();
            }}
        />
    );
};

export default DesktopPage;
