import { apiService } from 'core/services/apiService';
import { getTokenFromLocalStorage } from 'core/services/axiosInterceptors';
import { ROUTES } from 'module/navigation/enums/routes.enums';
import { Role } from 'module/schedule/interfaces/ScheduleInterface';
import { createContext, useContext, useEffect, useState } from 'react';

interface ContextProps {
    user: any;
    login: any;
    error: any;
    isLoggedin: any;
    isWorkplaceSelected: any;
    setUserFromLocaleStorage: any;
    logout: any;
    setIsManagerToLocale: (args: boolean) => void;
    isManager: boolean;
    role: Role[] | null;
    setRolesToLocal: any;
}

interface FormValues {
    email: string;
    password: string;
}

const authContext = createContext({} as ContextProps);

function ProvideAuth({ children }: { children: React.ReactNode }): JSX.Element {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuth(): ContextProps {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [isManager, setIsManager] = useState(false);
    const [role, setRole] = useState<Role[] | null>(null);

    const login = async (data: FormValues) => {
        try {
            setError(null);
            const { user, token_key, refresh_token_key } =
                apiService.responseHandler(
                    await apiService.post('/token', data)
                );

            localStorage.setItem(
                'BYOD-app',
                JSON.stringify({
                    user,
                    token_key,
                    refresh_token_key,
                    isManager: false,
                    role: [],
                    workplace: null,
                })
            );

            setUser(user);

            return true;
        } catch (error) {
            setError('Something wrong');
            return false;
        }
    };

    const isLoggedin = () => {
        if (getTokenFromLocalStorage('token_key')) {
            return true;
        }
        return false;
    };

    const isWorkplaceSelected = () => {
        if (getTokenFromLocalStorage('workplace')) {
            return true;
        }
        return false;
    };

    const setUserFromLocaleStorage = () => {
        const user = getTokenFromLocalStorage('user');
        const isManager = getTokenFromLocalStorage('isManager');
        const role = getTokenFromLocalStorage('role');
        setUser(user);
        setIsManager(isManager);
        setRole(role);
    };

    const logout = () => {
        localStorage.removeItem('BYOD-app');
        window.location.href = ROUTES.LOGIN;
    };

    const setIsManagerToLocale = async (isManager: boolean) => {
        const data = await JSON.parse(localStorage.getItem('BYOD-app')!);
        data.isManager = isManager;
        localStorage.setItem('BYOD-app', JSON.stringify(data));
        setIsManager(isManager);
    };

    const setRolesToLocal = async (roles: Role[]) => {
        setTimeout(async () => {
            const data = await JSON.parse(localStorage.getItem('BYOD-app')!);
            data.role = roles;
            localStorage.setItem('BYOD-app', JSON.stringify(data));
            setRole(roles);
        }, 200); // after workplace finish
    };

    useEffect(() => {
        setUserFromLocaleStorage();
    }, []);

    return {
        user,
        login,
        error,
        isLoggedin,
        isWorkplaceSelected,
        setUserFromLocaleStorage,
        logout,
        setIsManagerToLocale,
        isManager,
        setRolesToLocal,
        role,
    };
}

export { ProvideAuth, useAuth };
