import { IEventStaffing } from 'core/interfaces/staffingInterface';
import { apiService } from 'core/services/apiService';
import { IEvents } from 'module/schedule/interfaces/EventsInterface';
import moment from 'moment';

const LIMIT = 500;

export interface IPostStaffing {
    event: Event;
    role: Role;
    needed_number_of_staff: number;
}

export interface Event {
    id: number;
}

export interface Role {
    id: number;
}

interface IPostEvent {
    name: string;
    start: string;
    end: string;
    size: number;
    frame?: string;
    location: string;
    note?: string;
    i_calendar?: string;
    workplace: {
        id: number;
    };
    event_type: {
        id: number;
    };
    tags?: {
        id?: number;
    };
    organizer_full_name: string;
    organizer_email: string;
    organizer_phone_number: string;
    menu: string;
    banquet_event_order: string;
}

interface IUpdateEvent {
    id: number;
    body: Partial<IPostEvent>;
}

interface IFetchAllEvents {
    workplaceID: number;
    fromDate: string;
    toDate: string;
}

interface ISearchEvents {
    workplaceID: number;
    organizer_full_name: string;
    organizer_email: string;
    organizer_phone_number: string;
    from: string;
    to: string;
    page: number;
}

export const fetchAllEvents = async (values: IFetchAllEvents): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(
            `events?workplace=${values?.workplaceID}&from=${values?.fromDate}&to=${values?.toDate}`
        )
    );

    return data;
};

export const fetchSingleEvent = async (eventID: number): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(`events/${eventID}`)
    );

    return data;
};

export const fetchAllEventTypes = async (): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(`event-types?limit=${LIMIT}`)
    );

    return data;
};

export const postEvent = async (body: IPostEvent): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.post('events', body)
    );

    return data;
};

export const updateEvent = async (values: IUpdateEvent): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.put(`events/${values.id}`, values.body)
    );

    return data;
};

export const deleteEvent = async (eventID: number): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.delete(`events/${eventID}`)
    );

    return data;
};

// eslint-disable-next-line
export const fileUpload = async (file: any): Promise<{ path: string }> => {
    const { ...data } = apiService.responseHandler(
        await apiService.postUpload(`file/upload`, file)
    );

    return data;
};

export const searchEvents = async (params: ISearchEvents): Promise<IEvents> => {
    let queryString = `?workplace=${params.workplaceID}`;

    if (params.organizer_full_name) {
        queryString += `&organizer_full_name=${params.organizer_full_name}`;
    }
    if (params.organizer_email) {
        queryString += `&organizer_email=${params.organizer_email}`;
    }
    if (params.organizer_phone_number) {
        queryString += `&organizer_phone_number=${params.organizer_phone_number}`;
    }
    if (moment(params.from, true).isValid()) {
        queryString += `&from=${params.from}`;
    }
    if (moment(params.to, true).isValid()) {
        queryString += `&to=${params.to}`;
    }

    const { ...data } = apiService.responseHandler(
        await apiService.get(
            `events/customer-database${queryString}&limit=${5}&page=${
                params.page
            }&orderBy=id&direction=DESC`
        )
    );

    return data;
};

export const postStaffing = async (body: IPostStaffing): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.post('event-staffings', body)
    );

    return data;
};

export const deleteStaffing = async (staffingID: number): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.delete(`event-staffings/${staffingID}`)
    );

    return data;
};

export const getEventsStaffing = async (
    eventID: number
): Promise<IEventStaffing> => {
    const { ...data } = apiService.responseHandler(
        await apiService.get(`event-staffings?event=${eventID}`)
    );

    return data;
};
