import moment from 'moment';

const FORMATDATE = 'YYYY-MM-DD HH:mm:ss';

// calculate business day from timezone
// starts at 5am and ends just before 5am next day
// convert everything to UTC timezone because everything
// in database is stored in UTC
export const getBusinessDay = (timezone: string): string[] => {
    let testFromTimezone = moment
        .tz(timezone)
        // .subtract(1, 'day')
        .set({ hour: 5, minute: 0, second: 0 })
        .utc()
        .format(FORMATDATE);

    let testToTimezone = moment
        .tz(timezone)
        .add(1, 'day')
        .set({ hour: 4, minute: 59, second: 59 })
        .utc()
        .format(FORMATDATE);

    const activeTime = moment.tz(timezone).utc().format(FORMATDATE);

    // ako je pocetak vremena od 00 do 5am koristi dan prije

    const time = moment.tz(timezone);

    if (
        +time.format('HH') >= +'00' &&
        +time.format('mm') >= +'00' &&
        +time.format('HH') <= +'04' &&
        +time.format('mm') <= +'59'
    ) {
        testFromTimezone = moment
            .tz(timezone)
            .subtract(1, 'day')
            .set({ hour: 5, minute: 0, second: 0 })
            .utc()
            .format(FORMATDATE);

        testToTimezone = moment
            .tz(timezone)
            .set({ hour: 4, minute: 59, second: 59 })
            .utc()
            .format(FORMATDATE);
    }

    return [testFromTimezone, testToTimezone, activeTime];
};
