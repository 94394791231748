import moment from 'moment';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ClipLoader } from 'react-spinners';

import { GetOneShift, PutOneShift } from 'core/API/shifts';
import Loading from 'core/components/Loading/Loading';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useAuth } from 'module/auth/context/AuthContext';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
// CSS modules
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';

interface IShiftPickRequest {
    shiftPickID: number;
    closeShiftPickModalHandler: () => void;
}

export default function ShiftPickRequest(
    props: IShiftPickRequest
): JSX.Element {
    const auth = useAuth();
    const { showToast } = useToastContext();
    const { workkplaceID } = useWorkplacesContext();

    const [note, setNote] = useState('');

    const { data: shiftData, isLoading: shiftLoading } = useQuery(
        ['shift-one', props?.shiftPickID],
        () => GetOneShift(props.shiftPickID!)
    );

    const { mutate: updateOneShift, isLoading: loadingUpdateOneShift } =
        useMutation(PutOneShift);

    const handleSubmit = () => {
        const { ...restShiftPick } = shiftData;
        const updateShift = {
            id: restShiftPick.id,
            body: {
                department: {
                    id: restShiftPick.department.id,
                },
                end_at: restShiftPick.end_at,
                labor: {
                    wage_hourly_costs: 0,
                    wage_hourly_overtime_costs: 0,
                    scheduled_hours: 0,
                },
                note: note,
                open: false,
                published: false,
                scheduled: true,
                denied: false,
                shift_type: {
                    id: restShiftPick.shift_type.id,
                },
                start_at: restShiftPick.start_at,
                time_card_id: 0,
                user: {
                    id: auth.user.id,
                },
                workplace: {
                    id: workkplaceID,
                },
                reason: restShiftPick.reason
                    ? { id: restShiftPick.reason.id }
                    : null,
                category: restShiftPick.category
                    ? {
                          id: restShiftPick.category.id,
                      }
                    : null,
                predicted: true,
            },
        };

        updateOneShift(updateShift, {
            onSuccess: () => {
                showToast('success', 'Shift successfully updated!');
                props.closeShiftPickModalHandler();
            },
            onError: () => {
                showToast('error');
            },
        });
    };

    const handleNoteChange = (e: any) => {
        setNote(e.target.value);
    };

    return shiftLoading ? (
        <Loading />
    ) : (
        <>
            <div className={Forms.formGroup}>
                <p className={Forms.formLabel}>Employee who made request</p>
                <p className={Forms.formLockedText}>
                    {auth.user.first_name} {auth.user.last_name}
                </p>
            </div>
            <div className={Forms.formGroup}>
                <p className={Forms.formLabel}>When</p>
                <p className={Forms.formLockedText}>
                    {`${moment
                        .utc(shiftData?.start_at)
                        .format('dddd, D MMMM YYYY, h:mm A')} - ${moment
                        .utc(shiftData?.end_at)
                        .format('h:mm A')}`}
                </p>
            </div>
            <div className={Forms.formGroup}>
                <label className={Forms.formLabel} htmlFor="textarea">
                    Note
                </label>
                <textarea
                    className={Forms.formTextarea}
                    name="note"
                    id="textarea"
                    placeholder="Leave a note for your manager"
                    value={note}
                    onChange={handleNoteChange}
                ></textarea>
            </div>
            <footer className={Forms.formFooter}>
                {loadingUpdateOneShift ? (
                    <div>
                        <ClipLoader color="#841D80" loading={true} size={50} />
                    </div>
                ) : (
                    <>
                        <button
                            className={Buttons.btnPrimary}
                            onClick={handleSubmit}
                        >
                            Accept shift
                        </button>
                        <button
                            className={Buttons.btnText}
                            onClick={props.closeShiftPickModalHandler}
                        >
                            Cancel
                        </button>
                    </>
                )}
            </footer>
        </>
    );
}
