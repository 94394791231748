import { ReactComponent as Logo } from 'assets/icons/BYOD Logo_Tagling_Reverse.svg';

import { FC } from 'react';


// CSS modules
import AuthLayouts from './authLayout.module.scss';

interface IAuthLayout {
    children: JSX.Element;
}

export const AuthLayout: FC<IAuthLayout> = ({ children }) => {
    return (
        <div className={AuthLayouts.container}>
            <Logo />
            <div>{children}</div>
        </div>
    );
};
