import Laborsaber from 'module/laborsaber/pages/Laborsaber';
import SchedulePage from 'module/schedule/pages/SchedulePage';
import SituationRoom from 'module/situationRoom/pages/SituationRoom';
import { ROUTES } from '../enums/routes.enums';
import { INavigationItem } from '../interface/navigationItem.interface';

export const managerNavigationItems: INavigationItem[] = [
    {
        path: ROUTES.MANAGER,
        label: 'Schedule',
        contentComponent: SchedulePage,
    },
    {
        path: ROUTES.SITUATION_ROOM,
        label: 'Situation room',
        contentComponent: SituationRoom,
    },
    {
        path: ROUTES.LABORSABER,
        label: 'Laborsaber',
        contentComponent: Laborsaber,
    },
];
