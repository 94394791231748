import { apiService } from '../services/apiService';

export interface IToDoPost {
    description: string;
    log: {
        id: number;
    } | null;
    user: {
        id: number | null;
    } | null;
    priority: {
        id: number;
    };
    status: {
        id: number;
    };
    due_date: string | null;
    note:
        | {
              date: string;
              user: number;
              status: string;
              message: string;
              user_full_name: string;
          }[]
        | null;
    geolocation: {
        longitude: string;
        latitude: string;
    } | null;
    workplace: {
        id: number;
    };
}

export const PostToDo = async (body: IToDoPost): Promise<any> => {
    const { ...data } = apiService.responseHandler(
        await apiService.post(`todos`, body)
    );

    return data;
};
