import { ROUTES } from 'module/navigation/enums/routes.enums';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthForm } from '../components/AuthForm/AuthForm';
import { AuthLayout } from '../components/AuthLayout/AuthLayout';
import ChooseWorkplace from '../components/ChooseWorkplace/ChooseWorkplace';
import { useAuth } from '../context/AuthContext';

interface FormValues {
    email: string;
    password: string;
}

export default function LoginPage(): JSX.Element {
    const auth = useAuth();
    const { push, location } = useHistory();
    const [view, setView] = useState('auth');

    useEffect(() => {
        if (auth.isLoggedin()) {
            if (auth.isWorkplaceSelected()) {
                // if state exists that means its redirected from the app
                if (location.state) {
                    setView('chooseworkplace');
                } else {
                    if (auth.isManager) {
                        push(ROUTES.MANAGER);
                    } else {
                        push(ROUTES.EMPLOYEE);
                    }
                }
            } else {
                setView('chooseworkplace');
            }
        } else {
            return;
        }
    }, []);

    const submitClick = async (data: FormValues) => {
        if (await auth.login(data)) {
            setView('chooseworkplace');
        }
    };

    const chooseWorkplace = () => {
        if (auth.isManager) {
            push(ROUTES.MANAGER);
        } else {
            push(ROUTES.EMPLOYEE);
        }
    };

    return (
        <AuthLayout>
            {view === 'auth' ? (
                <AuthForm submitClick={submitClick} />
            ) : (
                <ChooseWorkplace chooseWorkplace={chooseWorkplace} />
            )}
        </AuthLayout>
    );
}
