import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { GetDepartmentsListOnWorkplace } from 'core/API/departments';
import { IPutOneShift, PostShifts, PostShiftsAddRole } from 'core/API/shifts';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useIsMount } from 'core/hooks/useIsMounted';
import { useAuth } from 'module/auth/context/AuthContext';
import { Item } from 'module/preload/interfaces/RolesInterface';
import { IDays } from 'module/schedule/interfaces/DaysInterface';
import { Role } from 'module/schedule/interfaces/ScheduleInterface';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
// CSS modules
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';

import SaveChangesDialog from '../../../schedule/Modals/SaveChangesDialog/SaveChangesDialog';

interface IAvailabilityModal {
    closeAvailibilityModal: () => void;
    fromDate: string;
}

function generateTimeArray(date?: any) {
    const halfHours = ['00', '30'];
    const times = [];

    const preselectedHour =
        date !== undefined ? parseInt(date?.split(':')[0]) : 0;
    const preselectedMinutes =
        date !== undefined ? (date?.split(':')[1] === '00' ? 0 : 1) : 0;

    let counter = 0;
    for (let i = preselectedHour; i < 24; i++) {
        for (let j = 0; j < 2; j++) {
            if (counter++ === 0) {
                j = preselectedMinutes;
            }

            let time = i + ':' + halfHours[j];
            if (i < 10) {
                time = '0' + time;
            }
            times.push(time);
        }
    }

    times.push('23:59');
    return times;
}

export default function AvailabilityModal(
    props: IAvailabilityModal
): JSX.Element {
    const { workkplaceID, timezone } = useWorkplacesContext();
    const { showToast } = useToastContext();
    const isMounted = useIsMount();
    const auth = useAuth();

    const [view, setView] = useState('Pref');

    const [departemntSelected, setdepartemntSelected] = useState<any>(null);

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const [days, setDays] = useState<IDays[]>([
        { id: 7, label: 'S', active: false, date: null },
        { id: 1, label: 'M', active: false, date: null },
        { id: 2, label: 'T', active: false, date: null },
        { id: 3, label: 'W', active: false, date: null },
        { id: 4, label: 'T', active: false, date: null },
        { id: 5, label: 'F', active: false, date: null },
        { id: 6, label: 'S', active: false, date: null },
    ]);

    const { mutate } = useMutation(PostShifts, {
        onSuccess: (e) => {
            // for every mutate request succesfull add according role to it
            // can use it in mutate below because it will only run once
            // and we need it here to be run for every day selected in form
            addRole(
                { shift: e.id, role: formik.values.role },
                {
                    onSuccess: () => {
                        showToast('success', 'Shift successfully added!');
                        props.closeAvailibilityModal();
                    },
                    onError: () => {
                        showToast('error');
                    },
                }
            );
        },
        onError: () => {
            showToast('error');
        },
    });

    const { mutate: addRole } = useMutation(PostShiftsAddRole);

    const { data: workplaceDepartments } = useQuery(
        ['workplace-departments', workkplaceID],
        () => GetDepartmentsListOnWorkplace(workkplaceID)
    );

    useEffect(() => {
        if (isMounted) {
            const curr = new Date(props.fromDate); // get current date
            const first = curr.getDate() - curr.getDay();

            const firstday = new Date(curr.setDate(first));

            const dt = new Date(firstday);
            let brojac = 0;

            const copyOfDays = [...days];
            while (brojac < 7) {
                copyOfDays[brojac].date = new Date(dt);
                dt.setDate(dt.getDate() + 1);
                brojac++;
            }

            setDays(copyOfDays);
        }
    }, []);

    const formik = useFormik<any>({
        initialValues: {
            start_at: '00:00',
            end_at: '00:00',
            note: '',
            department: 0,
            role: 0,
        },
        enableReinitialize: true,
        onSubmit: () => {
            const shiftsToSend = generateArryaOfShifts();
            shiftsToSend.forEach((value) => {
                // send post request for creating shift
                mutate(value.body);
            });
        },
    });

    const generateArryaOfShifts = () => {
        // when multiple days selected create array of shifts for API
        const activeDays = days.filter((day) => day.active);
        const arrShifts: IPutOneShift[] = [];
        const fmt = 'MM/DD/YYYY HH:mm:ss';
        activeDays.forEach((day) => {
            const date = new Date(day.date!);

            const start_at = new Date(date);
            start_at.setHours(
                parseInt(formik.values.start_at.split(':')[0]),
                parseInt(formik.values.start_at.split(':')[1])
            );

            const end_at = new Date(date);
            end_at.setHours(
                parseInt(formik.values.end_at.split(':')[0]),
                parseInt(formik.values.end_at.split(':')[1])
            );

            arrShifts.push({
                id: null,
                body: {
                    workplace: {
                        id: workkplaceID,
                    },
                    time_card_id: 0,
                    department: {
                        id: parseInt(`${formik.values.department}`),
                    },
                    user: { id: auth.user.id },
                    open: true,
                    published: false,
                    scheduled: false,
                    labor: {
                        wage_hourly_costs: 0,
                        wage_hourly_overtime_costs: 0,
                        scheduled_hours: 0,
                    },

                    //start_at: toIsoString(start_at),
                    start_at: moment
                        .tz(moment(start_at).format(fmt), fmt, timezone)
                        .utc()
                        .toDate()
                        .toISOString(),
                    //end_at: toIsoString(end_at),
                    end_at: moment
                        .tz(moment(end_at).format(fmt), fmt, timezone)
                        .utc()
                        .toDate()
                        .toISOString(),
                    note: formik.values.note || '',
                    denied: false,
                    shift_type: {
                        id: view === 'Pref' ? 8 : 7, // use real ID later on
                    },
                    reason: null,
                    category: null,
                    recurring: formik.values.recurring,
                    billable: formik.values.billable,
                    predicted: true,
                    role: {
                        id: formik.values.role,
                    },
                },
            });
        });

        return arrShifts;
    };

    const selectDay = (id: number) => {
        const newArrDays = days.map((value) => {
            if (value.id === id) {
                value.active = !value.active;

                return {
                    ...value,
                };
            }

            return value;
        });

        setDays(newArrDays);
    };

    const handleDepartmentChose = (e: any) => {
        formik.handleChange(e);
        // select certain department for showing roles from that department
        // in select dropdown below
        let dep;
        workplaceDepartments?.forEach((value) => {
            if (value.department.id === parseInt(e.target.value)) {
                dep = value.department;
            }
        });
        setdepartemntSelected(dep);

        formik.setFieldValue('role', 0);
    };

    const handleRoleChose = (e: any) => {
        formik.handleChange(e);
    };

    return (
        <>
            <div className={Forms.formTabGroup}>
                <p
                    className={`${Forms.formTabGroupItem} ${
                        view === 'Pref' ? Forms.formTabGroupItemActive : null
                    }`}
                    onClick={() => setView('Pref')}
                >
                    Preferred
                </p>
                <p
                    className={`${Forms.formTabGroupItem} ${
                        view === 'Unavailable'
                            ? Forms.formTabGroupItemActive
                            : null
                    }`}
                    onClick={() => setView('Unavailable')}
                >
                    Unavailable
                </p>
            </div>
            <div className={Forms.formGroup}>
                <label className={Forms.formLabel} htmlFor="select">
                    Department
                </label>
                <select
                    className={Forms.formSelect}
                    name="department"
                    id="select"
                    value={formik.values.department}
                    onChange={handleDepartmentChose}
                >
                    <option value="0" disabled>
                        Choose department
                    </option>

                    {/* display departments that loged in employee has roles */}
                    {workplaceDepartments?.map(({ department }: any) => {
                        let optionValue: React.DetailedHTMLProps<
                            React.OptionHTMLAttributes<HTMLOptionElement>,
                            HTMLOptionElement
                        > | null = null;
                        department.roles?.forEach((role: Role) => {
                            auth.role?.forEach((userRole: Role) => {
                                if (role.id === userRole.id) {
                                    optionValue = (
                                        <option
                                            value={department.id}
                                            key={department.id}
                                        >
                                            {department.name}
                                        </option>
                                    );
                                }
                            });
                        });

                        return optionValue;
                    })}
                </select>
            </div>
            <div className={Forms.formGroup}>
                <label className={Forms.formLabel} htmlFor="select">
                    Role
                </label>
                <select
                    className={Forms.formSelect}
                    name="role"
                    id="select"
                    value={formik.values.role}
                    onChange={handleRoleChose}
                    disabled={!formik.values.department}
                >
                    <option value="0" disabled>
                        Choose role
                    </option>

                    {departemntSelected?.roles?.map((role: Item) => {
                        return (
                            <option value={role.id} key={role.id}>
                                {role.name}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className={Forms.formGroupSplit}>
                <div className={Forms.formGroup}>
                    <label className={Forms.formLabel} htmlFor="select">
                        From
                    </label>
                    <select
                        className={Forms.formSelect}
                        name="start_at"
                        id="select"
                        value={formik.values.start_at}
                        onChange={formik.handleChange}
                    >
                        {generateTimeArray().map((value, index) => (
                            <option value={value} key={index}>
                                {value}
                            </option>
                        ))}
                        {/* <option value="1">1</option> */}
                    </select>
                </div>
                <div className={Forms.formGroup}>
                    <label className={Forms.formLabel} htmlFor="select">
                        To
                    </label>
                    <select
                        className={Forms.formSelect}
                        name="end_at"
                        id="select"
                        value={formik.values.end_at}
                        onChange={formik.handleChange}
                        disabled={!(formik.values.start_at !== '00:00')}
                    >
                        {generateTimeArray(formik.values.start_at).map(
                            (value, index) => (
                                <option value={value} key={index}>
                                    {value}
                                </option>
                            )
                        )}
                        {/* <option value="1">1</option> */}
                    </select>
                </div>
            </div>
            <div className={Forms.formGroup}>
                <label htmlFor="days" className={Forms.formLabel}>
                    Days
                </label>
                <div className={Forms.formChecker}>
                    {days.map((value, index) => (
                        <div
                            id="days"
                            className={`${Forms.formCheckerItem} ${
                                value.active
                                    ? Forms.formCheckerItemActive
                                    : null
                            }`}
                            key={index}
                            onClick={() => {
                                selectDay(value.id);
                            }}
                        >
                            <h5>{value.label}</h5>
                        </div>
                    ))}
                </div>
            </div>
            <div className={Forms.formGroup}>
                <label className={Forms.formLabel} htmlFor="textarea">
                    Note
                </label>
                <textarea
                    className={Forms.formTextarea}
                    name="note"
                    id="textarea"
                    placeholder="Write note here"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                ></textarea>
            </div>
            <footer className={Forms.formFooter}>
                <button
                    className={Buttons.btnText}
                    onClick={() => setIsDialogOpen(true)}
                >
                    Cancel
                </button>
                <button
                    className={Buttons.btnPrimary}
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.values.department || !formik.values.role}
                    type="submit"
                >
                    Save request
                </button>
            </footer>
            {isDialogOpen && (
                <SaveChangesDialog
                    save={formik.handleSubmit}
                    dontsave={props.closeAvailibilityModal}
                    goback={setIsDialogOpen}
                    isSubmitting={formik.isSubmitting}
                />
            )}
        </>
    );
}
