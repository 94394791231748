import React, { useState } from 'react';
import { ReactComponent as HappyFace } from 'assets/icons/HappyFace.svg';

import style from '../QuestionComp/QuestionComp.module.scss';
import welcomeStyle from '../Welcome/Welcome.module.scss';
import { GuestConnectReward } from 'module/guestconnect/interface/IGuestConnect';
import FooterButtons, { IFooterButtons } from '../FooterButtons/FooterButtons';

interface AwardProps extends IFooterButtons {
    rewards: GuestConnectReward[];
    awardHandler: (identifier: string) => void;
}

export default function Award({
    rewards,
    awardHandler,
    goNext,
    goBack,
}: AwardProps): JSX.Element {
    const [identifier, setIdentifier] = useState('');
    return (
        <>
            <p className={welcomeStyle.InfoText} style={{ fontSize: '22px' }}>
                Thanks for feedback! Please choose your reward.
            </p>
            {rewards.map((value, index) => (
                <div
                    className={`${style.SelectButtons} ${
                        value?.selected || identifier === value.identifier
                            ? style.SelectedButtonColor
                            : null
                    }`}
                    key={index}
                    onClick={() => {
                        awardHandler(value.identifier);
                        setIdentifier(value.identifier);
                    }}
                >
                    <HappyFace />
                    <p>{value.label}</p>
                </div>
            ))}

            <FooterButtons
                goNext={goNext}
                goBack={goBack}
                isDisabled={identifier === ''}
                showPrevious={false}
                label="Submit"
            />
        </>
    );
}
