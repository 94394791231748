import React, { useEffect, useState } from 'react';

import style from './GuestConnect.module.scss';
import PersonalInfo from '../ components/PersonalInfo/PersonalInfo';
import Welcome from '../ components/Welcome/Welcome';
import QuestionComp from '../ components/QuestionComp/QuestionComp';
import CommentComp from '../ components/CommentComp/CommentComp';
import Award from '../ components/Award/Award';
import { IGuestConnect } from '../interface/IGuestConnect';
import Submit from '../ components/Submit/Submit';
import logo from 'assets/images/logo.png';

interface GuestConnectProps {
    state: IGuestConnect;
}

export default function GuestConnect({
    state,
}: GuestConnectProps): JSX.Element {
    const [screenIndex, setScreenIndex] = useState(0);
    const [screensArray, setScreensArray] = useState([]);
    const [welcome, setWelcome] = useState('');

    useEffect(() => {
        const questionsLength =
            state?.guest_connect_additional_field.length + 1;
        const createArrayOfScreens = [];
        createArrayOfScreens.push(
            <Welcome
                welcome={welcome}
                setWelcome={setWelcome}
                goNext={goNext}
                goBack={goBack}
            />
        );
        if (welcome !== 'withoutInfo') {
            createArrayOfScreens.push(
                <PersonalInfo
                    state={state}
                    personalInfoHandler={personalInfoHandler}
                    goNext={goNext}
                    goBack={goBack}
                />
            );
        }
        state.guest_connect_additional_field.forEach((value, index) => {
            createArrayOfScreens.push(
                <QuestionComp
                    key={index}
                    value={value}
                    numberOfQuestion={index + 1}
                    questionsLength={questionsLength}
                    questionHandler={questionHandler}
                    goNext={goNext}
                    goBack={goBack}
                />
            );
        });
        createArrayOfScreens.push(
            <CommentComp
                numberOfQuestion={
                    state.guest_connect_additional_field.length + 1
                }
                questionsLength={questionsLength}
                commentHandler={commentHandler}
                goNext={goNext}
                goBack={goBack}
            />
        );

        if (welcome !== 'withoutInfo') {
            createArrayOfScreens.push(
                <Award
                    rewards={state.guest_connect_reward}
                    awardHandler={awardHandler}
                    goNext={goNext}
                    goBack={goBack}
                />
            );
        }

        createArrayOfScreens.push(<Submit state={state} welcome={welcome} />);

        setScreensArray(createArrayOfScreens);
    }, [welcome]);

    const personalInfoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        state?.guest_connect_basic_field?.forEach((value) => {
            if (value.identifier === e.target.name) {
                value.answer = e.target.value;
            }
        });
    };

    const questionHandler = (identifier: string, answer: string) => {
        state.guest_connect_additional_field.forEach((value) => {
            if (value.identifier === identifier) {
                value.answer = answer;
            }
        });
    };

    const commentHandler = (e) => {
        state.comment = e.target.value;
    };

    const awardHandler = (identifier: string) => {
        state.guest_connect_reward = state.guest_connect_reward.map((value) => {
            if (value.identifier === identifier) {
                return { ...value, selected: true };
            }
            return { ...value, selected: false };
        });
    };

    const displayScreen = () => {
        return screensArray[screenIndex];
    };

    const goNext = () => {
        setScreenIndex((prevValue) => prevValue + 1);
    };

    const goBack = () => {
        setScreenIndex((prevValue) => prevValue - 1);
    };

    return (
        <div className={style.Container}>
            <section className={style.ContainerBox}>
                <img src={logo} alt="Logo" className={style.ContainerBoxImg} />
                {displayScreen()}
            </section>
        </div>
    );
}
