import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProvideAuth } from 'module/auth/context/AuthContext';

// react query setup
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// import styles
import 'rsuite/dist/rsuite.min.css';
import './index.scss';
import { WorkplacesContextProvider } from 'module/workplaces/context/WorkplacesContext';
import { ToastContextProvidet } from 'core/components/Toast/context/ToastContext';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            suspense: false,
        },
    },
});

process.env.REACT_APP_SENTRY_ENV === 'staging' &&
    Sentry.init({
        dsn: 'https://598ff86a1059408b8b90596b377717b2@sentry.q-tests.com/49',
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });

ReactDOM.render(
    <React.StrictMode>
        <ToastContextProvidet>
            <ProvideAuth>
                <QueryClientProvider client={queryClient}>
                    <WorkplacesContextProvider>
                        <App />
                        <ReactQueryDevtools initialIsOpen={false} />
                    </WorkplacesContextProvider>
                </QueryClientProvider>
            </ProvideAuth>
        </ToastContextProvidet>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
