import { FC, FunctionComponentElement, SVGProps } from 'react';

import closeIcon from 'assets/icons/Close.svg';

import Portal from './Portal';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Modal from './modal.module.scss';

interface IModal {
    close: () => void;
    render: any;
    title: string;
    icon?: FunctionComponentElement<SVGProps<SVGSVGElement>>;
}

const CenterRemodal: FC<IModal> = ({
    children,
    close,
    render,
    title,
    icon,
}) => {
    return (
        <Portal>
            <div className={Modal.modalCenterRoot}>
                <div className={Modal.modalCenterWrapper}>
                    <div className={Modal.modalHeader}>
                        <div className={Modal.modalTitle}>
                            {icon && icon}
                            <h4>{title}</h4>
                        </div>
                        <SvgIcon
                            spriteUrl={closeIcon}
                            className={Modal.modalCloseIcon}
                            onClick={close}
                        />
                    </div>
                    {render(children) || children}
                </div>
                <div className={Modal.modalBackdrop} onClick={close} />
            </div>
        </Portal>
    );
};

export default CenterRemodal;
