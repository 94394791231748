import { FC, useState } from 'react';

import { ReactComponent as ArrowUp } from '../../../../assets/icons/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';

// CSS modules
import Sections from '../sections.module.scss';
import Buttons from 'styles/buttons.module.scss';
import { SalesNumber } from 'module/laborsaber/interface/Sales';
import moment from 'moment';

import ClipLoader from 'react-spinners/ClipLoader';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';

interface iNetSalse {
    isLoading: boolean;
    sales: SalesNumber;
}

const calculatePercentage = (a: number, b: number) => {
    // (a/b)*100

    const result = (a / b) * 100;

    if (a === 0 && b === 0) return 0;

    if (isFinite(result)) return result;

    return 100;
};

const displayValue = (value: number): string => {
    // return value.toFixed(2).replace(/[.,]00$/, '');
    return value.toFixed(2);
};

const DailyLaborCost: FC<iNetSalse> = ({ isLoading, sales }) => {
    const { timezone } = useWorkplacesContext();
    const [toggle, setToggle] = useState(true);

    return (
        <article
            style={{
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                padding: '24px',
                boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
                background: '#ffffff',
                gridArea: 'dailyLaborCost',
            }}
        >
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '400px',
                    }}
                >
                    <ClipLoader color="#841D80" loading={true} size={50} />
                </div>
            ) : (
                <>
                    <div className={Sections.header}>
                        {/* <div>
                    <span className={Sections.label}>Show information for</span>
                    <span className={Sections.labelDarker}>Yesterday</span>
                </div> */}
                        <h4
                            style={{
                                paddingLeft: '16px',
                                marginBottom: '20px',
                            }}
                        >
                            Daily Labor Cost
                        </h4>

                        <div>
                            <span className={Sections.label}>Data up to</span>
                            <span className={Sections.labelDarker}>
                                {moment.tz(timezone).format('HH:mm A')}
                            </span>
                        </div>
                    </div>
                    <div>
                        <span className={Sections.label}>
                            Compare to same day
                        </span>
                        <div className={Buttons.btnToggle}>
                            <button
                                type="button"
                                className={
                                    toggle
                                        ? Buttons.btnToggleActive
                                        : Buttons.btnToggleDefault
                                }
                                onClick={() =>
                                    setToggle((prevValue) => !prevValue)
                                }
                                style={{ width: '50%' }}
                            >
                                Today
                            </button>
                            <button
                                type="button"
                                className={
                                    !toggle
                                        ? Buttons.btnToggleActive
                                        : Buttons.btnToggleDefault
                                }
                                onClick={() =>
                                    setToggle((prevValue) => !prevValue)
                                }
                                style={{ width: '50%' }}
                            >
                                Last week
                            </button>
                        </div>
                    </div>
                    <div className={Sections.body}>
                        <div className={Sections.row}>
                            <span className={Sections.label}>
                                Projected Labor Cost
                            </span>
                            <div className={Sections.rowBody}>
                                <span className={Sections.rowContent}>
                                    ${' '}
                                    {toggle
                                        ? sales.daily_labor_cost.today.projected_labor_cost.toFixed(
                                              2
                                          )
                                        : sales.daily_labor_cost.last_week.projected_labor_cost.toFixed(
                                              2
                                          )}
                                </span>
                                <div className={Sections.rowIndicator}>
                                    {calculatePercentage(
                                        sales.daily_labor_cost.today
                                            .projected_labor_cost -
                                            sales.daily_labor_cost.last_week
                                                .projected_labor_cost,
                                        sales.daily_labor_cost.last_week
                                            .projected_labor_cost
                                    ) >= 0 ? (
                                        <ArrowUp />
                                    ) : (
                                        <ArrowDown />
                                    )}
                                    <span
                                        className={
                                            calculatePercentage(
                                                sales.daily_labor_cost.today
                                                    .projected_labor_cost -
                                                    sales.daily_labor_cost
                                                        .last_week
                                                        .projected_labor_cost,
                                                sales.daily_labor_cost.last_week
                                                    .projected_labor_cost
                                            ) >= 0
                                                ? Sections.rowContentPositive
                                                : Sections.rowContentNegative
                                        }
                                    >
                                        {/* {((sales.daily_labor_cost.today
                                            .projected_labor_cost -
                                            sales.daily_labor_cost.last_week
                                                .projected_labor_cost) /
                                            sales.daily_labor_cost.last_week
                                                .projected_labor_cost) *
                                            100 || 0} */}
                                        {displayValue(
                                            Math.abs(
                                                calculatePercentage(
                                                    sales.daily_labor_cost.today
                                                        .projected_labor_cost -
                                                        sales.daily_labor_cost
                                                            .last_week
                                                            .projected_labor_cost,
                                                    sales.daily_labor_cost
                                                        .last_week
                                                        .projected_labor_cost
                                                )
                                            )
                                        )}
                                        %
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={Sections.row}>
                            <span className={Sections.label}>
                                Mabel Alerted Labor Savings
                            </span>
                            <div className={Sections.rowBody}>
                                <span className={Sections.rowContent}>
                                    ${' '}
                                    {toggle
                                        ? sales.daily_labor_cost.today.mabel_labor_savings.toFixed(
                                              2
                                          )
                                        : sales.daily_labor_cost.last_week.mabel_labor_savings.toFixed(
                                              2
                                          )}
                                </span>
                                <div className={Sections.rowIndicator}>
                                    {calculatePercentage(
                                        sales.daily_labor_cost.today
                                            .mabel_labor_savings -
                                            sales.daily_labor_cost.last_week
                                                .mabel_labor_savings,
                                        sales.daily_labor_cost.last_week
                                            .mabel_labor_savings
                                    ) >= 0 ? (
                                        <ArrowUp />
                                    ) : (
                                        <ArrowDown />
                                    )}
                                    {/* <ArrowDown /> */}
                                    {/* <span className={Sections.rowContentNegative}> */}
                                    <span
                                        className={
                                            calculatePercentage(
                                                sales.daily_labor_cost.today
                                                    .mabel_labor_savings -
                                                    sales.daily_labor_cost
                                                        .last_week
                                                        .mabel_labor_savings,
                                                sales.daily_labor_cost.last_week
                                                    .mabel_labor_savings
                                            ) >= 0
                                                ? Sections.rowContentPositive
                                                : Sections.rowContentNegative
                                        }
                                    >
                                        {/* {((sales.daily_labor_cost.last_week
                                            .mabel_labor_savings -
                                            sales.daily_labor_cost.today
                                                .mabel_labor_savings) /
                                            sales.daily_labor_cost.today
                                                .mabel_labor_savings) *
                                            100 || 0} */}
                                        {displayValue(
                                            Math.abs(
                                                calculatePercentage(
                                                    sales.daily_labor_cost.today
                                                        .mabel_labor_savings -
                                                        sales.daily_labor_cost
                                                            .last_week
                                                            .mabel_labor_savings,
                                                    sales.daily_labor_cost
                                                        .last_week
                                                        .mabel_labor_savings
                                                )
                                            )
                                        )}
                                        %
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={Sections.row}>
                            <span className={Sections.label}>
                                Actual Labor Cost
                            </span>
                            <div className={Sections.rowBody}>
                                <span className={Sections.rowContent}>
                                    ${' '}
                                    {toggle
                                        ? sales.daily_labor_cost.today.actual_labor_cost.toFixed(
                                              2
                                          )
                                        : sales.daily_labor_cost.last_week.actual_labor_cost.toFixed(
                                              2
                                          )}
                                </span>
                                <div className={Sections.rowIndicator}>
                                    {calculatePercentage(
                                        sales.daily_labor_cost.today
                                            .actual_labor_cost -
                                            sales.daily_labor_cost.last_week
                                                .actual_labor_cost,
                                        sales.daily_labor_cost.last_week
                                            .actual_labor_cost
                                    ) >= 0 ? (
                                        <ArrowUp />
                                    ) : (
                                        <ArrowDown />
                                    )}
                                    {/* <ArrowUp /> */}
                                    <span
                                        className={
                                            calculatePercentage(
                                                sales.daily_labor_cost.today
                                                    .actual_labor_cost -
                                                    sales.daily_labor_cost
                                                        .last_week
                                                        .actual_labor_cost,
                                                sales.daily_labor_cost.last_week
                                                    .actual_labor_cost
                                            ) >= 0
                                                ? Sections.rowContentPositive
                                                : Sections.rowContentNegative
                                        }
                                    >
                                        {/* {((sales.daily_labor_cost.last_week
                                            .actual_labor_cost -
                                            sales.daily_labor_cost.today
                                                .actual_labor_cost) /
                                            sales.daily_labor_cost.today
                                                .actual_labor_cost) *
                                            100 || 0} */}
                                        {displayValue(
                                            Math.abs(
                                                calculatePercentage(
                                                    sales.daily_labor_cost.today
                                                        .actual_labor_cost -
                                                        sales.daily_labor_cost
                                                            .last_week
                                                            .actual_labor_cost,
                                                    sales.daily_labor_cost
                                                        .last_week
                                                        .actual_labor_cost
                                                )
                                            )
                                        )}
                                        %
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </>
            )}
        </article>
    );
};

export default DailyLaborCost;
