import { CalendarNav, CalendarNext, CalendarPrev, CalendarToday, Eventcalendar, MbscCalendarEvent, MbscCalendarEventData, MbscEventcalendarView, MbscResource, momentTimezone } from '@mobiscroll/react';
import momentTZ from 'moment-timezone';
import { useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { GetUsersList } from 'core/API/users';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useAuth } from 'module/auth/context/AuthContext';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { IWorkplaces } from 'module/workplaces/interface/WorkplacesInterface';

import './DesktopPage.style.scss';

// setup Mobiscroll Moment plugin
momentTimezone.moment = momentTZ;

interface IDesktopPage {
    myEvents: MbscCalendarEvent[];
    setView: (args: string) => void;
    view: string;
    updateTimeRange: (firstday: Date, lastday: Date) => void;
    handleTradePickingShift: (args: any) => void;
    tradeMyShift: MbscCalendarEvent | null;
}

export default function DesktopPage(props: IDesktopPage): JSX.Element {
    const queryClient = useQueryClient();
    const auth = useAuth();
    const { showToast } = useToastContext();

    const { workkplaceID, timezone } = useWorkplacesContext();

    const workplacesData = queryClient.getQueryData<IWorkplaces>([
        'workplaces',
    ]);

    const [calView, setCalView] = useState<MbscEventcalendarView>({
        timeline: {
            type: 'day',
            timeCellStep: 60,
            timeLabelStep: 60,
        },
    });

    const renderScheduleEvent = useCallback((data: MbscCalendarEventData) => {
        const original = data.original!;

        return (
            <div
                className={`md-timeline-template-event ${
                    original.overlap ? 'hasStripeBackground' : ''
                } 
                ${original.isTradeSelected ? 'trade-outline' : ''}
                ${!original.scheduled ? 'requestsEventCustom' : ''}
                `}
                style={{
                    cursor: 'pointer',
                    backgroundColor: original?.background_color,
                    color: original?.text_color,
                }}
            >
                <div className="md-timeline-template-event-cont">
                    <div className="md-timeline-template-time">
                        {data.start} - {data.end}
                    </div>
                    <div className="md-timeline-template-role">
                        {original?.role ?? 'No Role'}
                    </div>
                </div>
            </div>
        );
    }, []);

    const { data: usersData } = useQuery(['users', workkplaceID], () =>
        GetUsersList(workkplaceID)
    );

    const myResources = useMemo<MbscResource[]>(() => {
        const usersListResource: MbscResource[] = [];
        const firstUser: MbscResource[] = [];

        usersData?.forEach((value) => {
            if (value.user.id === auth.user.id) {
                firstUser.push({
                    id: value.user.id,
                    name: `${value.user.first_name} ${value.user.last_name}`,
                    role: value.role,
                });
            } else {
                usersListResource.push({
                    id: value.user.id,
                    name: `${value.user.first_name} ${value.user.last_name}`,
                    role: value.role,
                });
            }
        });

        usersListResource.sort((a, b) => {
            let userAshift = null;
            let userBshift = null;

            props.myEvents.forEach((shift) => {
                if (shift.resource === a.id) {
                    userAshift = shift;
                } else if (shift.resource === b.id) {
                    userBshift = shift;
                }
            });

            if (userAshift !== null && userBshift !== null) {
                const firstShiftStart = new Date(userAshift.start).getTime();
                const secondShiftStart = new Date(userBshift.start).getTime();

                const titleA = userAshift.title.split(' ');
                const titleB = userBshift.title.split(' ');

                return (
                    userAshift.role.localeCompare(userBshift.role) ||
                    firstShiftStart - secondShiftStart ||
                    titleB[titleB.length - 1].localeCompare(
                        titleA[titleA.length - 1]
                    )
                );
            }

            // if last user does not have shift push them to the end
            if (userBshift === null) {
                return -1;
            }

            return 0;
        });

        return [...firstUser, ...usersListResource]!;
    }, [usersData]);

    const changeView = (event: any) => {
        let calView: MbscEventcalendarView;

        switch (event) {
            case 'day': {
                calView = {
                    timeline: {
                        type: 'day',
                        timeCellStep: 60,
                        timeLabelStep: 60,
                    },
                };
                break;
            }
            case 'workweek':
                calView = {
                    timeline: {
                        type: 'week',
                        startDay: 1,
                        endDay: 5,
                    },
                };
                break;
            case 'week':
            default: {
                calView = {
                    timeline: {
                        type: 'week',
                        startDay: 1,
                        endDay: 0,
                        eventList: true,
                        timeCellStep: 1440,
                        timeLabelStep: 1440,
                    },
                };
                break;
            }
        }

        props.setView(event);
        setCalView(calView);
    };

    const renderMyHeader = () => {
        return (
            <>
                <div className="customHeader">
                    <div className="customHeaderTop">
                        <CalendarNav className="md-work-week-nav" />
                        <div className="md-work-week-picker">
                            <div className="toggleSchdule">
                                <button
                                    className={`${
                                        props.view === 'day' ? 'active' : null
                                    }`}
                                    onClick={() => changeView('day')}
                                >
                                    Day
                                </button>
                                <button
                                    className={`${
                                        props.view === 'week' ? 'active' : null
                                    }`}
                                    onClick={() => changeView('week')}
                                >
                                    Week
                                </button>
                            </div>
                        </div>
                        <div>
                            <CalendarPrev className="md-work-week-prev" />
                            <CalendarToday className="md-work-week-today" />
                            <CalendarNext className="md-work-week-next" />
                        </div>
                    </div>
                    <div className="customHeaderBottom">
                        <span className="WorkplaceTitle">
                            Workplace:
                            <span className="WorkplaceName">
                                {workplacesData?.items?.map(
                                    ({ workplace }) =>
                                        workkplaceID === workplace.id &&
                                        workplace.name
                                )}
                            </span>
                        </span>
                    </div>
                </div>
            </>
        );
    };

    const renderMyResource = (resource: MbscResource) => {
        return (
            <div className={`md-work-week-cont `}>
                <div className="md-work-week-name">{resource.name}</div>
                <div className="md-work-week-title">
                    {resource?.role?.map(({ name }: any) => `${name} `)}
                </div>
            </div>
        );
    };

    return (
        <Eventcalendar
            theme="ios"
            themeVariant="light"
            dataTimezone={timezone}
            displayTimezone={timezone}
            timezonePlugin={momentTimezone}
            clickToCreate={false}
            dragToCreate={false}
            dragToMove={true}
            dragToResize={true}
            view={calView}
            data={props.myEvents}
            renderScheduleEvent={renderScheduleEvent}
            resources={myResources}
            renderHeader={renderMyHeader}
            renderResource={renderMyResource}
            dragTimeStep={30}
            cssClass="md-switching-timeline-view-cont md-timeline-height"
            timeFormat="HH:mm"
            externalDrop={true}
            onEventClick={(e) => {
                if (e.event.isTradeSelected) {
                    if (!props.tradeMyShift) {
                        props.handleTradePickingShift(e.event);
                    } else {
                        myResources.forEach((resource) => {
                            if (resource.id === e.event.resource) {
                                if (
                                    resource.role.filter(
                                        (value: any) =>
                                            value.name ===
                                            props?.tradeMyShift?.role
                                    ).length
                                ) {
                                    props.handleTradePickingShift(e.event);
                                } else {
                                    showToast(
                                        'error',
                                        'User does not have role for this shift!'
                                    );
                                }
                            }
                        });
                    }
                }
            }}
            onPageChange={(event) => {
                props.updateTimeRange(event.firstDay, event.lastDay);
            }}
        />
    );
}
