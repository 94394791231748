import { FC } from 'react';

import ClipLoader from 'react-spinners/ClipLoader';

// CSS modules
import Buttons from 'styles/buttons.module.scss';
import Dialogs from './SaveChangesDialog.module.scss';

interface ISaveChangesDialog {
    goback?: any;
    save?: any;
    dontsave?: any;
    isSubmitting?: boolean;
}

const SaveChangesDialog: FC<ISaveChangesDialog> = ({
    goback,
    save,
    dontsave,
    isSubmitting,
}) => {
    return (
        <div className={Dialogs.dialogWrapper}>
            <div className={Dialogs.dialogContent}>
                <h5>Changes not saved</h5>
                <p>
                    You made changes on this screen. Do you wish to save them or
                    exit without saving?
                </p>
                <footer className={Dialogs.dialogFooter}>
                    {isSubmitting ? (
                        <ClipLoader color="#841D80" loading={true} size={50} />
                    ) : (
                        <>
                            <button
                                type="submit"
                                className={Buttons.btnPrimary}
                                onClick={save}
                            >
                                Save changes
                            </button>
                            <button
                                className={Buttons.btnText}
                                onClick={dontsave}
                            >
                                Don&apos;t save
                            </button>
                            <button
                                className={Buttons.btnTextSecondary}
                                onClick={() => goback(false)}
                            >
                                Go back
                            </button>
                        </>
                    )}
                </footer>
            </div>
        </div>
    );
};

export default SaveChangesDialog;
