import { FC, useState } from 'react';
import { useIsFetching, useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'module/navigation/enums/routes.enums';

import useModal from 'core/hooks/useModal';
import { useAuth } from 'module/auth/context/AuthContext';

import { GetNotifications, PutNotifications } from 'core/API/notification';
import { NotificationItem } from 'core/interfaces/notificationInterface';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import ClipLoader from 'react-spinners/ClipLoader';

import { fetchTopPriority } from 'core/API/situationRoom';

import logo from 'assets/icons/BYOD Logo_Full Color_Gradient_CMYK.svg';
import hamburger from 'assets/icons/Hamburger.svg';
import highPriority from 'assets/icons/HighPriorityIcon.svg';
import lowPriority from 'assets/icons/LowPriorityIcon.svg';
import mediumPriority from 'assets/icons/MediumPriorityIcon.svg';
import notificationBell from 'assets/icons/NotificationBell.svg';
import user from 'assets/icons/User.svg';

import MobileMenu from 'core/components/MobileMenu/MobileMenu';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';

// CSS modules
import Header from './HeaderBar.module.scss';

const HeaderBar: FC = (): JSX.Element => {
    const [menu, setMenu] = useState<boolean>(false);
    const [notification, setNotification] = useState<boolean>(false);
    const { push, location } = useHistory();

    const auth = useAuth();
    const { workkplaceID } = useWorkplacesContext();

    const isFetching = useIsFetching();

    const {
        open: isMobileMenuOpen,
        openModal: openMobileMenu,
        closeModal: closeMobileMenu,
    } = useModal();

    const { data: currMaxPriority, isFetched: isCurrMaxPriorityFetched } =
        useQuery(['top-priority'], () => fetchTopPriority(workkplaceID));

    const renderPriorityIcon = () => {
        switch (currMaxPriority?.top_priority) {
            case 'Low':
                return (
                    <SvgIcon
                        spriteUrl={lowPriority}
                        className={Header.navIconLow}
                    />
                );
            case 'Medium':
                return (
                    <SvgIcon
                        spriteUrl={mediumPriority}
                        className={Header.navIconMedium}
                    />
                );
            case 'High':
                return (
                    <SvgIcon
                        spriteUrl={highPriority}
                        className={Header.navIconHigh}
                    />
                );
            case 'Urgent':
                return (
                    <SvgIcon
                        spriteUrl={highPriority}
                        className={Header.navIconHigh}
                    />
                );
            default:
                return null;
        }
    };

    const { data } = useQuery('notifications', GetNotifications, {
        refetchInterval: 10000,
    });

    const { mutate } = useMutation(PutNotifications);

    const markAsReadHandler = () => {
        data?.items.forEach((value) => {
            const sendObj = {
                notification: value.id,
                content: {
                    user: {
                        id: auth.user.id,
                    },
                    active: false,
                    readed: true,
                },
            };
            mutate(sendObj);
        });
        setNotification(false);
    };

    return (
        <>
            <header className={Header.header}>
                <SvgIcon
                    spriteUrl={hamburger}
                    className={Header.navHamIcon}
                    onClick={() => openMobileMenu()}
                />
                <img src={logo} alt="BYOD logo" className={Header.headerLogo} />
                <nav className={Header.nav}>
                    <ul className={Header.navList}>
                        <li className={Header.navListItem}>
                            <NavLink
                                exact
                                to={
                                    auth.isManager
                                        ? ROUTES.MANAGER
                                        : ROUTES.EMPLOYEE
                                }
                                className={Header.navListLink}
                                activeClassName={Header.navListLinkActive}
                            >
                                Hyperscheduler
                            </NavLink>
                        </li>
                        {auth.isManager && (
                            <li className={Header.navListItem}>
                                <NavLink
                                    to={ROUTES.LABORSABER}
                                    className={Header.navListLink}
                                    activeClassName={Header.navListLinkActive}
                                >
                                    Laborsaber
                                </NavLink>
                            </li>
                        )}
                        {auth.isManager && (
                            <li
                                className={Header.navListItem}
                                style={{ display: 'flex' }}
                            >
                                <NavLink
                                    to={ROUTES.SITUATION_ROOM}
                                    className={Header.navListLink}
                                    activeClassName={Header.navListLinkActive}
                                    style={{ marginRight: '10px' }}
                                >
                                    Situation room
                                </NavLink>
                                {isCurrMaxPriorityFetched &&
                                    renderPriorityIcon()}
                            </li>
                        )}
                    </ul>
                    <section className={Header.headerInfo}>
                        {isFetching ? (
                            <ClipLoader
                                color="#841D80"
                                loading={!!isFetching}
                                size={25}
                            />
                        ) : null}

                        <a
                            className={Header.navListLink}
                            onClick={() => setMenu((value) => !value)}
                        >
                            <SvgIcon
                                spriteUrl={user}
                                className={Header.navUserIcon}
                            />
                            <span className={Header.navListLinkLabel}>
                                {auth?.user?.first_name} {auth?.user?.last_name}
                            </span>
                        </a>
                        {menu && (
                            <>
                                <div className={Header.headerDropdown}>
                                    <a
                                        onClick={() =>
                                            push({
                                                pathname: ROUTES.LOGIN,
                                                state: location.pathname,
                                            })
                                        }
                                    >
                                        Switch workplace
                                    </a>
                                    <a href="#" onClick={auth.logout}>
                                        Log out
                                    </a>
                                </div>
                                <div
                                    className={Header.headerDropdownBackdrop}
                                    onClick={() => setMenu((value) => !value)}
                                ></div>
                            </>
                        )}
                        <SvgIcon
                            spriteUrl={notificationBell}
                            className={Header.navNotificationIcon}
                            onClick={() => setNotification((value) => !value)}
                        />

                        {notification ? (
                            <>
                                <div
                                    className={`${Header.Dropdown}  ${Header.DropdownNotif}`}
                                >
                                    <div
                                        className={Header.NotificationButton}
                                        onClick={markAsReadHandler}
                                    >
                                        <h6>Mark all as read</h6>
                                    </div>
                                    {data?.items?.map(
                                        (value: NotificationItem) => {
                                            return (
                                                <p key={value.id}>
                                                    {value.message}
                                                </p>
                                            );
                                        }
                                    )}
                                </div>
                                <div
                                    className={Header.DropDownBackdrop}
                                    onClick={() =>
                                        setNotification((value) => !value)
                                    }
                                ></div>
                            </>
                        ) : null}
                    </section>
                </nav>
            </header>
            {isMobileMenuOpen && <MobileMenu close={() => closeMobileMenu()} />}
        </>
    );
};

export default HeaderBar;
